import React, { useState } from "react";
import "./listing.scss";
import OfferToolTip from "./offertooltip";
import noImage from "../../assets/images/noImage.png";
import ReactTooltip from "react-tooltip";
import Cookie from "js-cookie";
import {
  faLongArrowAltRight,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { addLike, removeLike } from "../../util/service";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NZToolTip from "../listing/newownedtooltip";
const token = Cookie.get("AUTH_TOKEN") ? Cookie.get("AUTH_TOKEN") : null;

const stripURL = (url) => {
  const temp = url.replace(/\/$/, "");
  return temp.replace(/^(.*[\\\/])/g, "");
};

const NewListing = (props) => {
  const [app, setApp] = useState(props.data);
  const [link, setLink] = useState(`${props.link}/${stripURL(props.data.url)}`);
  const [WishlistAdded, setWishlistAdded] = useState(props.wishlist);
  const [url, SetURL] = useState(props.likeURL);
  const [colour] = useState(props.blue);

  const redirect = (e) => {
    if (e.target.parentNode.classList.contains("hearticon")) {
    } else if (e.target.classList.contains("hearticon")) {
    } else {
      window.location.href = `${link}`;
    }

    // if (e.target.parentNode.classList.contains("hearticon")) {
    // } else if (e.target.classList.contains("hearticon")) {
    // } else {
    //   window.location.href = `${link}`;
    // }
  };

  const addFunction = async () => {
    try {
      const data = await addLike({
        listing: app.url,
      });
      const likes = await data.data.json();
      setWishlistAdded(true);
      props.handleLikes(data);
      SetURL(likes.url);
    } catch (error) {
      console.log(error.message);
    }
  };

  const removeFunction = async () => {
    const urltoremove = url.split("/").slice(-2).join("/");

    try {
      await removeLike({
        listing: urltoremove,
      });
      setWishlistAdded(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div
      class={`listing_new ${props.className ? props.className : ""} ${
        colour ? "blue_listing" : ""
      }`}
      onClick={(e) => redirect(e)}
    >
      <div class="listingLogo_new">
        <img
          src={
            app.logo === null || app.logo === undefined || app.logo === ""
              ? noImage
              : app.logo
          }
          alt="The Listing logo"
        ></img>
      </div>
      <div class="listingDetail_new">
        <div class="listingWrapper_new">
          <div>
            {token ? (
              <div class="hearticonWrapper">
                <FontAwesomeIcon
                  className={
                    token && WishlistAdded
                      ? "pointer hearticon inline-block  heartFilledYes"
                      : "pointer heartOutline hearticon"
                  }
                  icon={WishlistAdded ? faHeart : farHeart}
                  onClick={WishlistAdded ? removeFunction : addFunction}
                />
              </div>
            ) : (
              <div class="hearticonWrapper">
                <FontAwesomeIcon
                  className={"pointer heartOutline hearticon"}
                  icon={farHeart}
                  data-tip
                  data-for={app.name}
                  onClick={() =>
                    (window.location.href =
                      "https://admin.therighttool.co.nz/auth/registration")
                  }
                />
                <ReactTooltip
                  id={app.name}
                  className="tooltip"
                  aria-haspopup="true"
                  effect="solid"
                >
                  <span class="tooltiptitle">
                    Sign up to save this to your toolbox
                  </span>
                  <span>Click me to create an account</span>
                </ReactTooltip>
              </div>
            )}
            {app.special_offers.length > 0 && (
              <OfferToolTip
                id={`${app.name}_offer`}
                colour={props.blue}
                data={app.special_offers}
              />
            )}
            {app.nz_owned && (
              <NZToolTip
                id={`${app.name}_nz`}
                right={app.special_offers.length > 0}
              />
            )}

            <h4>{app.name}</h4>
          </div>
        </div>
        <p class="listing_p_new">
          {app.short_description.length > 110
            ? app.short_description.substr(0, 110 - 1) + "..."
            : app.short_description}
        </p>

        <a href={link}>
          <span class="blue">
            Learn more &nbsp;
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </span>
        </a>
      </div>
    </div>
  );
};

export default NewListing;
