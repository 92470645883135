import React from "react";
import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";
import { Helmet } from "react-helmet";
// import Cookie from "js-cookie";
// const token = Cookie.get("token") ? true : false;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  return (
    <div class="min-height">
      <Helmet>
        <title> List your app for free on The Right Tool</title>
        <meta
          name="description"
          content="Your listing will give you the opportunity to acquire high buying intent web traffic and sales leads from small business owners ready to try and buy your solution."
        />
      </Helmet>
      <Menu transparent={false} />
      {children}
      <Footer />

    </div>
  );
};
