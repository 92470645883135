import React, { Component } from "react";
import "./filter.scss";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "aria-components";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
      data: [],
      loading: true,
      selected: "Select an Industry",
      default: "Select an Industry",
    };
  }

  componentDidMount() {
    const pattern = window.location.href.match(/tools\/(.*)/)[1];

    const current = pattern.substring(0, pattern.lastIndexOf("/"));

    let industryfromURL;

    if (this.props.id === "main_categoryDrop") {
      const currentone = current.replace(/-/g, " ");
      // console.log(subcategory, this.state.selected);
      this.setState({ selected: currentone, default: currentone });
    } else {
      industryfromURL = /[^/]*$/.exec(window.location.href)[0];
      if (industryfromURL.length > 2) {
        this.setState({
          selected: industryfromURL,
          default: industryfromURL,
        });
      } else {
        this.setState({
          selected: "Select an Industry",
          default: "Select an Industry",
        });
      }
    }

    this.fetchIndustries();
  }

  fetchIndustries = async () => {
    try {
      const response = await this.props.query();
      const data = await response.data.json();

      this.setState({ data: data }, () => {
        const controller = document.getElementById(`${this.props.id}`);

        const target = document.getElementById(`${this.props.idlist}`);

        const listbox = new Listbox({
          controller,
          target,
          onInit: () => {},
          onStateChange: (e) => {
            // if (!e.expanded) {
            //   e.activeDescendant.click();
            // }
          },
          onDestroy: () => {},
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  SetTitle(subcategory, i) {
    if (subcategory === this.state.selected) return null;
    this.setState({ selected: subcategory });
    // console.log(subcategory);
    const stripspaces = subcategory.replace(/ /g, "-");
    this.props.setFilter(stripspaces);
  }

  render() {
    const select = this.state.selected.replace(/-/g, " ");
    return (
      <div class={`filter_drop-down`}>
        <span
          id="filter_dropDown"
          class="filter_drop-down__button pointer filter_drop-down__name"
        >
          {" "}
          <button id={`${this.props.id}`} class="filterul">
            {decodeURI(select)}
          </button>{" "}
          &nbsp;
          <FontAwesomeIcon
            icon={!this.state.menuActive ? faChevronDown : faChevronDown}
          />
        </span>

        <div class="filter_drop-down__menu-box">
          <ul id={`${this.props.idlist}`} class="filter_drop-down__menu">
            {this.state.data.map((subcategories, i) => {
              return (
                <li
                  key={i}
                  onClick={(i) => {
                    this.SetTitle(subcategories.name, i);
                  }}
                  data-name="profile"
                  class="filter_drop-down__item"
                >
                  {subcategories.name.replace(/ /g, " ")}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Filter;
