import React, { Component } from 'react'
import FilterMain from '../components/filter_main/filter_main'
import Casestudylisting from '../components/casestudylisting/casestudylisting'
import NewListing from '../components/newlisting/newlisting'
import Button from '../components/button/button'
import db1 from '../assets/images/db1.png'
import db2 from '../assets/images/db2.png'
import grow from '../assets/images/row.png'
import website from '../assets/images/website.png'
import manage from '../assets/images/manage.png'
import marketing from '../assets/images/market.png'
import accounting from '../assets/images/accounting.png'
import { Helmet } from 'react-helmet'
import Loading from '../components/loading/loading'
import { randomproviders } from '../util/service'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from 'swiper/core'
import 'swiper/swiper.min.css'
// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow])

function handleClick () {
  window.location.href = '/free-tool-finder'
}

function About () {
  return (
    <div id="categories_main" class="gc no-gap center_all content">
      <div class="d-2-12 t-2-12 m-3-11 text_center iconsBelow">
        <h2 class="padding_h2">
          It's hard work running a business,{' '}
          <span class="blue">but having the right tools helps</span>
        </h2>

        <p class="main_p">
          The Right Tool is a free marketplace of business apps, tailored to
          help Kiwi businesses like yours find the right tool for your business.
        </p>
      </div>
      <div class="d-2-12 t-2-12 m-1-13 gc profile">
        <Casestudylisting
          button_link="/tools/website/"
          button_text="Learn More"
          img={website}
          title={['To build your ', <span class="red">website</span>]}
          qoute={`So you can get an online storefront.`}
        />
        <Casestudylisting
          button_link="/tools/accounting/"
          button_text="Learn More"
          img={accounting}
          title={['To manage your ', <span class="red">accounting</span>]}
          qoute={`So you can manage your cash flow.`}
        />
      </div>
      <div class="d-2-12 t-2-12 m-1-13 gc profile">
        <Casestudylisting
          button_link="/tools/marketing/"
          button_text="Learn More"
          img={marketing}
          title={[
            'To help you ',
            <span class="red">market</span>,
            ' your business',
          ]}
          qoute={`So you can win more business.`}
        />
        <Casestudylisting
          button_link="/tools/growth/"
          button_text="Learn More"
          img={grow}
          title={[
            'To find insights to ',
            <span class="red">grow</span>,
            ' your business',
          ]}
          qoute={`So you can turn your data into actionable insights.`}
        />
      </div>
      <div class="d-2-12 t-2-12 m-1-13 gc profile">
        <Casestudylisting
          button_link="/tools/managing-my-business/"
          button_text="Learn More"
          img={manage}
          title={[
            'To help you ',
            <span class="red">manage</span>,
            ' your business',
          ]}
          qoute={`So you can improve your productivity.`}
        />
        <div
          class="listing casestudylisting background_dgrey flex"
          onClick={() => handleClick()}
        >
          <h4>Not sure where to start?</h4>
          <h4 class="blue">Try the Free Tool Finder</h4>
          <p class="listing_p">
            Get tailored app recommendations specific to your business for free.
          </p>
          <div class="btn_container">
            <div class="button background_blue">
              <span class="white">Try now</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function DigitalBoost (props) {
  return (
    <div
      id="digitalboost"
      class="gc no-gap center_all content background_supergrey"
    >
      <div class="d-2-12 t-2-12 m-2-12 text_center">
        <h2>
          Learn more about the other{' '}
          <span class="red">Digital Boost initiatives </span>
        </h2>

        <p class="main_p">
          Digital Boost is the government funded initiative to help drive
          digital transformation and success in the New Zealand economy.
        </p>
      </div>
      <div class="d-2-12 t-3-11 m-1-13 gc profile">
        <Casestudylisting
          button_link="https://digitalboostalliance.nz//"
          button_text="Get Free Training"
          img={db1}
          title={'Learn the digital skills to boost your business'}
          qoute={`‘Digital’ doesn’t have to be intimidating. It’s here to make life easy! So whatever stage you’re at, we’ll help you use digital to do business smarter.`}
        />
        <Casestudylisting
          button_link="https://mailtrack.io/trace/link/bfeeb19e9466a41170f97f169e4002976056e2fe?url=https%3A%2F%2Fwww.business.govt.nz%2Fdo-business-online%2Fdigital-boost&userId=4068589&signature=5d0594a8c967c5dc"
          button_text="Watch the series"
          img={db2}
          title={'Watch the Digital Boost Spotlight Series'}
          qoute={`See how small business owners who have recently transformed their business by adopting digital tools and digital ways of working share their experiences to benefit other small business owners.`}
        />
      </div>
    </div>
  )
}

class ImageCTA extends Component {
  c

  constructor (props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  scrolltop (e, link) {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  render () {
    return (
      <div id="imageCTAcontainer" class="gc no-gap center_all background_blue ">
        <div class="d-2-7 t-3-11 m-2-12 content ">
          <h2 class="white">Get set up by the experts</h2>
          <p class="main_p white">
            Find cloud experts to help you set them up. So you can get the help
            when you need it.
          </p>
          <div class="pointer" onClick={() => this.scrolltop()}>
            <Button text="Find Cloud Experts" color="white" link={null} />
          </div>
        </div>

        <div
          id="imageSpacing"
          class="d-8-12 t-3-11 m-1-13 imageCTA t-hide m-hide"
        >
          <Swiper
            className="mySwiper swiper-container"
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {this.props.data.map((provider) => (
              <SwiperSlide>
                {' '}
                <NewListing data={provider} link={`/profile/cloud-expert`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    )
  }
}

class landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      directory: '',
      message: '',
      urlstate: '',
      doneLoading: false,
    }
  }

  callbackFunction = (childData, url) => {
    this.setState({
      message: childData,
      urlstate: url,
    })
  }

  fetchRandom = (e) => {
    randomproviders()
      .then(function (response) {
        const responser = response.data
        return responser.json()
      })
      .then((data) => {
        this.setState({
          data: data,
          doneLoading: true,
        })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  componentDidMount () {
    this.fetchRandom()
  }

  callbackFunctionLoading = () => {}

  render () {
    if (!this.state.doneLoading) {
      return (
        <div class="landing">
          <Loading />
        </div>
      )
    } else {
      return (
        <div class="opacityfade">
          <Helmet>
            <meta
              name="description"
              content="Find the right tools to help you run your small business. Quickly find peer reviewed apps and software to help to manage and grow your business. Get started."
            />
            <meta
              property="og:image"
              content="https://therighttool.co.nz/TheRightTool.jpg"
            />
            <title>The Right Tool - Aotearoa’s leading app marketplace</title>
          </Helmet>
          <FilterMain
            search={true}
            text={[
              <span class="background_gradient">Find the right tools </span>,
              <br></br>,
              'for your business',
            ]}
            parentParentCallback={this.callbackFunction}
            homepagepass={this.state.message}
          />

          <About />
          <ImageCTA data={this.state.data} />
          <DigitalBoost />
        </div>
      )
    }
  }
}

export default landing
