import React from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import landing from "./pages/landing";
import apps from "./pages/apps";
import Tools from "./pages/tools";
import services from "./pages/services";
import story from "./pages/story";
import listservice from "./pages/listservice";
import listapp from "./pages/listapp";
import privacy from "./pages/privacy";
import casestudy1 from "./pages/casestudy1";
import casestudies from "./pages/casestudy";
import toolfinder from "./pages/tool-finder";
import terms from "./pages/terms";
import contact from "./pages/contact";
import searchResults from "./pages/searchResults";

import General from "./layouts/general";
import Profile from "./layouts/profile";
import Results from "./layouts/results";
import V2Results from "./layouts/V2Results";
import profileapps from "./pages/profileapps";
import profileservices from "./pages/profileservices";

import ScrollToTop from "./actions/scrollToTop";

const Main = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/tools">
          <V2Results>
            <Switch>
              <Route path="/tools/:category/:industry?" component={Tools} />
            </Switch>
          </V2Results>
        </Route>

        <Route path="/directory">
          <Results>
            <Switch>
              <Route
                path="/directory/cloud-experts/:handle"
                exact
                component={services}
              />

              <Redirect
                from="/directory/cloud-experts/:handle/r/"
                to="/directory/cloud-experts/:handle"
              />
              <Route path="/directory/apps/:handle" exact component={apps} />
              <Redirect
                from="/directory/apps/:handle/r/"
                to="/directory/apps/:handle"
              />
              {/* <Route render={() => <Redirect to="/" />} /> */}
            </Switch>
          </Results>
        </Route>

        <Route path="/search">
          <Profile>
            <Switch>
              <Route path="/search/results" exact component={searchResults} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Profile>
        </Route>

        <Route path="/profile">
          <Profile>
            <Switch>
              <Route
                path="/profile/app/:handle"
                exact
                component={profileapps}
              />
              <Route
                path="/profile/cloud-expert/:handle"
                exact
                component={profileservices}
              />
            </Switch>
          </Profile>
        </Route>

        <Route path="/">
          <General>
            <ScrollToTop />
            <Switch>
              <Route path="/" exact component={landing} />
              <Route path="/story" exact component={story} />
              <Route path="/list-an-app" exact component={listapp} />
              <Route
                path="/list-a-cloud-expert"
                exact
                component={listservice}
              />
              <Route path="/terms-of-use" exact component={terms} />
              <Route path="/contact-us" exact component={contact} />
              <Route path="/privacy-policy" exact component={privacy} />
              <Route path="/case-studies/" exact component={casestudies} />
              <Route
                path="/case-studies/wild-pilates"
                exact
                component={casestudy1}
              />
              <Route path="/free-tool-finder" exact component={toolfinder} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </General>
        </Route>

        {/* <Route exact path='/dashboard' component={dashboard}></Route> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
