import React, { Component } from 'react'
import FilterMainOld from '../components/filter_main/filtermain_old'
import SmallCTA from '../components/small_cta/small_cta'
import logo_blue from '../assets/images/logo_blue.png'
import image_4 from '../assets/images/ourstory_image_4.png'
import { Helmet } from 'react-helmet'
import logogovblack from '../assets/images/dboost.png'

function About (props) {
  return (
    <div class="gc no-gap center_all content">
      <div class="d-2-7 t-3-11 m-2-12">
        <h2 class="padding_h2">
          The Right Tool™ is Aotearoa’s marketplace of business apps and cloud
          experts, tailored to help Kiwi businesses quickly find and get setup
          with the right tools to use to help you manage your business.
        </h2>
      </div>
      <div class="d-7-12 t-hide m-hide text_center">
        <img src={logo_blue} alt="The Righ Tool logo" />
      </div>
    </div>
  )
}

function OurStory (props) {
  return (
    <div id="OurStory" class="gc  center_all content">
      <div class="d-2-7 t-hide m-hide">
        <img src={image_4} alt="Small businesses" />
      </div>
      <div class="d-7-12 t-3-11 m-3-11 ">
        <p class="main_p">
          It’s never been easier to start and run a business, and hundreds of
          apps and digital tools are available for each industry niche, helping
          take some of the hard work away. Yet finding the right tools that are
          suitable for your business and budget can be time consuming and
          uninspiring.
          <br></br>
          <br></br>
          The Right Tool™ is your no-nonsense tailored app directory, designed
          to help you find the most relevant tools to digitise, manage and grow
          your business no matter what stage you are at.
          <br></br>
          <br></br>
          To support more small businesses to adopt the apps and tools listed on
          the directory, The Right Tool™ also connects small business owners
          with verified and reputable cloud experts, integrators and advisers
          who specialise in helping setup the listed tools in each industry
          category.
          <br></br>
          <br></br>
          The Right Tool is the brainchild of Alex Hamilton and Denym Bird. Alex
          and Denym have over six years’ experience working in and running
          technology-focused businesses. Our most recent success was{' '}
          <a class="blue pointer" href="https://delivereat.co.nz/">
            delivereat.co.nz
          </a>{' '}
          – a directory of food merchants who were delivering during New
          Zealand’s initial COVID lockdown. Our directory attracted over one
          million Kiwis to the platform over a four week period, and was
          acquired shortly after by digital ordering and online system Mobi2Go.
          <br /><br />
          The Right Tool is an initiative of the Digital Boost Alliance which
          was established to help members develop commitments that will
          collectively drive digital acceleration in Aotearoa. You can find out
          more about the Alliance <a href={'http://digitalboostalliance.nz'}
                                     target={'_blank'}
                                     rel={'noreferrer noopener'}>at their
          website</a> .
        </p>

        <div className="supportedBy">
          <span>Supported by</span>
          <a href="https://digitalboostalliance.nz//"
             title="open external link">
            <img src={logogovblack} alt="Small Business Digital Boost logo" />
          </a>
        </div>
      </div>
    </div>
  )
}

class story extends Component {
  render () {
    return (
      <div id="site_page">
        <Helmet>
          <meta
            name="description"
            content="Your no-nonsense tailored app directory, designed to help you find the most relevant tools to digitise, manage and grow your business no matter what stage you are at."
          />
          <title>Learn about why we created The Right Tool</title>
        </Helmet>
        <FilterMainOld
          search={false}
          top="Our Story"
          text={
            'It’s hard yakka running a business, but having The Right Tool helps'
          }
        />
        <About />
        <OurStory />
        <SmallCTA
          sub={'Looking for best of breed software for your business?'}
          button_text={'Check out the app directory'}
          button_link={'/directory/apps/accounting?n=Accounting'}
        />
      </div>
    )
  }
}

export default story
