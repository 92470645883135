import React, { Component } from "react";
import "./listing.scss";
import OfferToolTip from "./offertooltip";
import ReactStars from "react-rating-stars-component";
import noImage from "../../assets/images/noImage.png";
import ReactTooltip from "react-tooltip";
import flag from "../../assets/images/flagsvg.png";
import Cookie from "js-cookie";
import {
  faLongArrowAltRight,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const token = Cookie.get("AUTH_TOKEN") ? Cookie.get("AUTH_TOKEN") : null;

class Listing extends Component {
  clickedListing(e, link) {
    if (e.target.parentNode.classList.contains("hearticon")) {
    } else if (e.target.classList.contains("hearticon")) {
    } else {
      window.location.href = `${link}`;
    }
  }

  render() {
    var addFunction = this.props.addFunction;
    if (this.props.data === undefined) {
      return null;
      // <p class="listing_p">Hmmm looks like we could find any tools.</p>
    } else if (this.props.link.includes("/profile/cloud-expert")) {
      var addFunction = this.props.addFunction;
      const name = this.props.data.name;
      const apps = this.props.data.apps ? this.props.data.apps : [];
      const offers = this.props.data.special_offers;
      const location =
        this.props.data.locations[0] && this.props.data.locations[0].name;
      const pricing =
        this.props.data.pricing === "nan" ? null : this.props.data.pricing;
      const stars = this.props.data.rating;
      const logo = this.props.data.logo;
      const shortdesc = this.props.data.short_description;
      const link = this.props.link;
      const num_reviews = this.props.data.num_reviews;
      return (
        <div class="listing" onClick={(e) => this.clickedListing(e, link)}>
          <div class="listingLogo">
            <img
              className="m-hide"
              src={
                logo === null || logo === undefined || logo === ""
                  ? noImage
                  : logo
              }
              alt="The Listing logo"
            ></img>
          </div>
          <div class="listingDetail">
            <div class="listingWrapper">
              <div>
                {" "}
                <img
                  className={"d-hide t-hide"}
                  src={
                    logo === null || logo === undefined || logo === ""
                      ? noImage
                      : logo
                  }
                  alt="The Listing logo"
                ></img>
              </div>
              <div>
                <p class="listing_p m-hide">{location}</p>
                {token ? (
                  <div class="hearticonWrapper">
                    <FontAwesomeIcon
                      className={
                        token && this.props.wishlist
                          ? "pointer hearticon inline-block  heartFilledYes"
                          : "pointer heartOutline hearticon"
                      }
                      icon={this.props.wishlist ? faHeart : farHeart}
                      onClick={() => addFunction(this.props.data)}
                    />
                  </div>
                ) : (
                  <div class="hearticonWrapper">
                    <FontAwesomeIcon
                      className={"pointer heartOutline hearticon"}
                      icon={farHeart}
                      data-tip
                      data-for={name}
                    />
                    <ReactTooltip
                      id={name}
                      className="tooltip"
                      aria-haspopup="true"
                      effect="solid"
                    >
                      <span class="tooltiptitle">
                        Sign up to save this to your toolbox
                      </span>
                      <span>Click me to create an account</span>
                    </ReactTooltip>
                  </div>
                )}
                {offers.length > 0 && <OfferToolTip data={offers} />}

                <h4>{name}</h4>
              </div>
            </div>

            <div class="starssidebyside">
              <ReactStars
                value={stars ? stars : 0}
                size="30"
                edit={false}
                activeColor={"#FAAA00"}
                color={"#767676"}
              />
              <span class="darkgrey"> - {num_reviews} Reviews</span>
            </div>
            <p class="listing_p">{shortdesc}</p>
            <p class="listing_p pricing_p">{pricing}</p>
            <div class="app_contaienr">
              {apps.map((app, i) => {
                if (app.logo) {
                  if (i > 3) {
                    return null;
                  }
                  return (
                    <img
                      alt="The featured apps logo"
                      class="featuredapplogo"
                      src={app.logo}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <a href={link}>
              <span class="blue">
                Contact Provider &nbsp;
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </span>
            </a>
          </div>
          <div class={this.props.data.nz_owned ? "flag" : "hide"}>
            {" "}
            <img src={flag} />
          </div>
        </div>
      );
    } else {
      const name = this.props.data.name;
      const industry = this.props.data.subcategories;
      const pricing =
        this.props.data.pricing === "nan" ? null : this.props.data.pricing;
      const stars = this.props.data.rating;
      let logo = this.props.data.logo;
      const offers = this.props.data.special_offers;
      const shortdesc = this.props.data.short_description;
      const link = this.props.link;
      const num_reviews = this.props.data.num_reviews;
      var addFunction = this.props.addFunction;
      return (
        <div class="listing" onClick={(e) => this.clickedListing(e, link)}>
          <div class="listingLogo">
            <img
              className="m-hide"
              src={
                logo === null || logo === undefined || logo === ""
                  ? noImage
                  : logo
              }
              alt="The Listing logo"
            ></img>
          </div>
          <div class="listingDetail">
            <div class="listingWrapper">
              <div>
                {" "}
                <img
                  className={"d-hide t-hide"}
                  src={
                    logo === null || logo === undefined || logo === ""
                      ? noImage
                      : logo
                  }
                  alt="logo"
                ></img>
              </div>

              <div>
                {" "}
                <p class="listing_p m-hide">
                  {industry.map((data, i) => {
                    let length = industry.length;
                    if (length === i + 1) {
                      return `${data.name}`;
                    } else {
                      return `${data.name}, `;
                    }
                  })}
                </p>
                <h4>{name}</h4>
              </div>
              <div class="hearticonWrapper">
                {token ? (
                  <FontAwesomeIcon
                    className={
                      token && this.props.wishlist
                        ? "pointer hearticon inline-block  heartFilledYes hearticon"
                        : "pointer heartOutline hearticon"
                    }
                    icon={this.props.wishlist ? faHeart : farHeart}
                    onClick={() => addFunction(this.props.data)}
                  />
                ) : (
                  <div class="hearticonWrapper m-hide">
                    <a href="https://admin.therighttool.co.nz/auth/registration">
                      <FontAwesomeIcon
                        className={"pointer heartOutline hearticon"}
                        icon={farHeart}
                        data-tip
                        data-for={name}
                      />

                      <ReactTooltip
                        id={name}
                        className="tooltip"
                        aria-haspopup="true"
                        effect="solid"
                      >
                        <span class="tooltiptitle">
                          Sign up to save this to your toolbox
                        </span>
                        <span>Click me to create an account</span>
                      </ReactTooltip>
                    </a>
                  </div>
                )}
                {/* {offers.length > 0 && <OfferToolTip data={offers} />} */}
              </div>
            </div>

            <div class="starssidebyside">
              {" "}
              <ReactStars
                value={stars ? stars : 0}
                size="30"
                edit={false}
                activeColor={"#FAAA00"}
                color={"#767676"}
              />{" "}
              <span class="darkgrey">{num_reviews} Reviews</span>
            </div>
            <p class="listing_p">{shortdesc}</p>
            <p class="listing_p pricing_p">{pricing}</p>
            <a href={link}>
              <span class="blue">
                Learn More &nbsp;
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </span>
            </a>
          </div>
          <div class={this.props.data.nz_owned ? "flag" : "hide"}>
            {" "}
            <img src={flag} alt="new zealand flag" />
          </div>
        </div>
      );
    }
  }
}

export default Listing;
