import React, { Component } from "react";
import Search from "../components/search/search";
import Result from "../components/search/results";
import SmallCTA from "../components/small_cta/small_cta";
import { Helmet } from "react-helmet";
class searchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading } = this.state;
    let query = new URLSearchParams(window.location.search).get("search");

    return (
      <div id="results_page">
        <Helmet>
          <meta
            name="description"
            content="Find the right tools to help you run your small business. Quickly find peer reviewed apps and software to help to manage and grow your business. Get started."
          />
          <meta
            property="og:image"
            content="https://therighttool.co.nz/TheRightTool.jpg"
          />
          <title>Search Results - The Right Tool</title>
        </Helmet>
        <Search />
        <Result data={query} />
        <SmallCTA
          sub={"Looking for best of breed software for your business?"}
          button_text={"Check out the app directory"}
          button_link={"/directory/apps/accounting?n=Accounting"}
        />
      </div>
    );
  }
}

export default searchResults;
