const Loading = () => {
  return (
    <div class="gc no-gap splashscreen">
      <div class="d-2-12 t-3-11 m-1-13 ">
        <svg class="splash-spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
    </div>
  );
};
export default Loading;
