import React, { Component } from "react";
import "./button.scss";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class Button extends Component {
  render() {
    const text = this.props.text;
    const link = this.props.link;

    if (this.props.color === "blue") {
      return (
        <div class="btn_container">
          <a
            class={this.props.classname ? this.props.classname : ""}
            href={link}
          >
            <div class="button background_blue">
              <span class="white">
                {text} &nbsp;
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </span>
            </div>
          </a>
        </div>
      );
    } else if (this.props.color === "green") {
      return (
        <div class="btn_container">
          <a
            class={this.props.classname ? this.props.classname : ""}
            href={link}
          >
            <div class="button background_blue">
              <span class="white">{text}</span>
            </div>
          </a>
        </div>
      );
    } else if (this.props.color === "grey") {
      return (
        <div class="btn_container">
          <a
            class={this.props.classname ? this.props.classname : ""}
            href={link}
          >
            <div class="button background_grey">
              <span class="blue">{text}</span>
            </div>
          </a>
        </div>
      );
    } else if (this.props.color === "dblue") {
      return (
        <div class="btn_container">
          <a
            class={this.props.classname ? this.props.classname : ""}
            href={link}
          >
            <div class="button background_dblue">
              <span class="white">{text}</span>
            </div>
          </a>
        </div>
      );
    } else if (this.props.color === "red") {
      // alert(this.props.clickable);
      return (
        <div class="btn_container">
          <a
            class={this.props.classname ? this.props.classname : ""}
            href={link}
          >
            <div
              class={
                this.props.clickable
                  ? "button background_red "
                  : "button background_darkred"
              }
            >
              <span class="white">{text}</span>
            </div>
          </a>
        </div>
      );
    } else {
      return (
        <div class="btn_container">
          <a
            class={this.props.classname ? this.props.classname : ""}
            href={link}
          >
            <div class="button background_white">
              <span class="blue">{text}</span>
            </div>
          </a>
        </div>
      );
    }
  }
}

export default Button;
