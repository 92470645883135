import React from "react";
import nz_owned from "../../assets/images/nz_owned.svg";
import ReactTooltip from "react-tooltip";

const NZToolTip = (props) => {
  return (
    <div class={`offertip flag ${props.right && "moveflag"}`}>
      <ReactTooltip
        id={`${props.id}_flag`}
        className="tooltip"
        aria-haspopup="true"
        effect="solid"
      >
        <span>NZ Owned</span>
      </ReactTooltip>
      <img
        data-tip
        data-for={`${props.id}_flag`}
        alt="nz flag"
        src={nz_owned}
      />
    </div>
  );
};

export default NZToolTip;
