import React, { Component } from 'react'
import Filter from '../components/filter/filter'
import Listing from '../components/listing/listing'
import Cookie from 'js-cookie'
import CTA from '../components/cta/cta'
import { Helmet } from 'react-helmet'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null
const secure = window.location.protocol === 'https'

function fadeIn (el, time) {
  el.style.opacity = 0

  var last = +new Date()
  var tick = function () {
    el.style.opacity = +el.style.opacity + (new Date() - last) / time
    last = +new Date()

    if (+el.style.opacity < 1) {
      (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
      setTimeout(tick, 16)
    }
  }
  tick()
}

class services extends Component {
  constructor (props) {
    super(props)
    this.state = {
      industries: '',
      featured: [],
      verifed: [],
      loading: true,
      locationFilter: 'All Locations',
      locations: [],
      app_likes: [],
      url: '',
    }
  }

  setlocationFilter (someArg) {
    this.setState({ locationFilter: someArg })
  }

  fetcher () {
    fetch(process.env.REACT_APP_API_URL + `/likes/services/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ app_likes: this.state.data })
        for (let i = 0; i < this.state.app_likes.length; i++) {
          const element = this.state.app_likes[i]
        }
      })
      .catch(error => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  addFunction = e => {
    fetch(process.env.REACT_APP_API_URL + `/likes/services/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: e.url,
      }),
    })
      .then(response => response.json())
      .then(data => {
        let dataarr = data
        let array = {
          listing: {
            url: data.listing,
          },
          url: data.url,
        }
        this.setState({ wishlistAdded: true })
        var joined = this.state.app_likes.concat(array)

        this.setState({ app_likes: joined })
      })
      .catch(error => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  removeFunction = i => {
    let urldelete = i.url
    for (let i = 0; i < this.state.app_likes.length; i++) {
      if (this.state.app_likes[i].listing.url === urldelete) {
        let url = this.state.app_likes[i].url
        var array = [...this.state.app_likes] // make a separate copy of the array

        var index = array.indexOf(i)
        array.splice(i, 1)
        this.setState({ app_likes: array })
        fetch(
          process.env.REACT_APP_API_URL + `/likes/services/${url
            .split('/')
            .slice(-2)
            .join('/')}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${token}`,
            },
          },
        )
          .then(data => {
            this.setState({ wishlistAdded: false })
          })
          .catch(error => {
            this.setState({ error: error })
            console.log(this.state.error)
          })
      }
    }
  }

  componentWillMount () {
    if (this.props.match.params.handle) {
      this.setState({ industries: this.props.match.params.handle },
        function () {
          if (token) {
            fetch(process.env.REACT_APP_API_URL + `/likes/services/`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
              },
            })
              .then(response => response.json())
              .then(data => {
                this.setState({ app_likes: data })
                this.fetchIndustriesListings()
              })
              .catch(error => {
                this.setState({ error: error })
                console.log(this.state.error)
              })
          } else {
            this.fetchIndustriesListings()
          }
        })
    }
  }

  fetchIndustriesListings = e => {
    fetch(
      process.env.REACT_APP_API_URL + `/industries/${escape(
        this.state.industries,
      )}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        let featured = []
        let verifed = []
        this.setState({ loading: false })
        for (let i = 0; i < data.services.length; i++) {
          if (data.services[i].featured === true) {
            featured.push(data.services[i])
          } else {
            verifed.push(data.services[i])
          }
        }

        // setTimeout(this.fadeInDivs(), 5000);

        this.setState({ featured: featured, verifed: verifed })
      })
      .catch(error => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  fadeInDivs = () => {
    let profiles = document.getElementsByClassName('conn')
    for (let i = 0; i < profiles.length; i++) {
      setTimeout(function () {
        fadeIn(profiles[i], 500)
      }, 200 * i)
    }
  }

  render () {
    const locationFilter = this.state.locationFilter
    var setlocationFilter = this.setlocationFilter
    var addFunction = this.addFunction
    var removeFunction = this.removeFunction
    const { featured, verifed, loading, app_likes } = this.state
    if (loading) {
      return (
        <div class="gc no-gap splashscreen">
          <div class="d-2-12 t-3-11 m-1-13 ">
            <svg class="splash-spinner" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        </div>
      )
    }
    if (featured.length === 0 && verifed.length === 0) {
      return (
        <div class="opacityfade">
          <div class="content center_all text_center">
            <h4>Empty, try another industry.</h4>
          </div>
          <CTA
            text={'Do you provide a software service?'}
            sub={
              'Verify yourself as a cloud expert and start helping kiwi businesses get set up with best of breed software.'
            }
            button_text={'List My Cloud Expert Profile'}
            button_link={''}
          />
        </div>
      )
    } else {
      return (
        <div id="maincontentskip" class="opacityfade">
          <Helmet>
            <title>
              {' '}
              Find the best {this.state.industries} apps for your business - The
              Right Tool
            </title>
            <meta
              name="description"
              content={`Real reviews for ${this.state.industries} apps to help you find the tools to help you manage your [INDUSTRY] business. Aotearoa’s leading ${this.state.industries} app directory. Quickly find the best ${this.state.industries} apps.`}
            />
          </Helmet>
          <div
            class={
              this.state.featured.length > 0
                ? 'gc no-gap center_all listing_container'
                : 'empty '
            }
          >
            <div class="d-2-12 t-2-12 m-2-12 ">
              <h2>Featured Cloud Experts</h2>
            </div>
            <div class="d-2-12 t-1-13 m-1-13 gc profile">
              {this.state.featured.map(function (listing, i) {
                let URLvar = listing.url.replace(/\/$/, '')
                URLvar = URLvar.replace(/^(.*[\\\/])/g, '')

                let wishlistAdded = false

                for (let i = 0; i < app_likes.length; i++) {
                  if (app_likes[i].listing.url === listing.url) {
                    wishlistAdded = true
                  }
                }

                return (
                  <Listing
                    addFunction={
                      wishlistAdded
                        ? removeFunction.bind(this)
                        : addFunction.bind(this)
                    }
                    wishlist={wishlistAdded}
                    data={listing}
                    link={`/profile/cloud-expert/${URLvar}`}
                  />
                )
              })}
            </div>
          </div>

          <div
            class={
              this.state.verifed.length > 0
                ? 'gc no-gap center_all listing_container verfied'
                : 'empty '
            }
          >
            <div class="d-2-12 t-2-12 m-2-12 ">
              <div class="split_between">
                <h2>Verifed Cloud Experts</h2>
                <Filter
                  id="1filterul"
                  idlist="1filterlist"
                  location={true}
                  setFilter={setlocationFilter.bind(this)}
                />
              </div>
            </div>
            <div class="d-2-12 t-1-13 m-1-13 gc profile">
              {this.state.verifed.map(function (listing, i) {
                let URLvar = listing.url.replace(/\/$/, '')
                URLvar = URLvar.replace(/^(.*[\\\/])/g, '')

                let wishlistAdded = false
                for (let i = 0; i < app_likes.length; i++) {
                  if (app_likes[i].listing.url === listing.url) {
                    wishlistAdded = true
                  }
                }

                for (let j = 0; j < listing.locations.length; j++) {
                  if (listing.locations[j].name === locationFilter) {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/cloud-expert/${URLvar}`}
                      />
                    )
                  } else if (locationFilter === 'All Locations') {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/cloud-expert/${URLvar}`}
                      />
                    )
                  } else {
                    return null
                  }
                }
              })}
            </div>
          </div>

          <CTA
            text={'Do you provide a software service?'}
            sub={
              'Verify yourself as a cloud expert and start helping kiwi businesses get set up with best of breed software.'
            }
            button_text={'List Your Cloud Expert Profile'}
            button_link={'/list-a-cloud-expert'}
          />
        </div>
      )
    }
  }
}

export default services
