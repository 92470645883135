import React, { useState, useEffect } from "react";
import noImage from "../../assets/images/noImage.png";
import "./search.scss";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactStars from "react-rating-stars-component";

const SearchItem = props => {
  // const isvisible = this.props.visble
  const [data, setData] = useState(props.data);
  let isApp = true;
  let locationOrSub;
  let URLvar = data.url.replace(/\/$/, "");
  URLvar = URLvar.replace(/^(.*[\\\/])/g, "");

  if (data.locations) {
    isApp = false;
    locationOrSub = data.locations[0].name;
  } else {
    locationOrSub = data.subcategories[0].name;
  }

  return (
    <div
      class="searchItem"
      onClick={e =>
        (window.location.href = `/profile/${
          isApp ? "app" : "service"
        }/${URLvar}`)
      }
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div>
          <img
            className="m-hide"
            src={
              data.logo === null || data.logo === undefined || data.logo === ""
                ? noImage
                : data.logo
            }
            alt="The Listing logo"
          ></img>
        </div>
        <div class="searchItemInfo">
          <div>
            <span class="isApp"> {isApp ? "Product" : "Cloud Expert"}</span>·
            <span class="locationOrSub">{locationOrSub}</span>
          </div>
          <div>
            <span class="searchItemName">{data.name}</span>
          </div>
          <div class="starssidebyside">
            <ReactStars
              classNames={!data.rating && "hide"}
              value={data.rating && data.rating}
              size="20"
              edit={false}
              activeColor={"#FAAA00"}
              color={"#767676"}
            />
            <span class={!data.rating && "hide"}>
              {`${data.num_reviews}  `} Reviews
            </span>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <a href={``}>
          <FontAwesomeIcon icon={faLongArrowAltRight} />
        </a>
      </div>
    </div>
  );
};

export default SearchItem;
