import React, { Component } from "react";
import FilterMainOld from "../components/filter_main/filtermain_old";
import Casestudylisting from "../components/casestudylisting/casestudylisting";
import wp from "../assets/images/wp.png";
import wplogo from "../assets/images/wplogo.png";
import { Helmet } from "react-helmet";

function OurStory(props) {
  return (
    <div class="gc no-gap center_all listing_container">
      <div class="d-1-13 t-1-13 m-1-13 gc profile">
        <div class="d-2-12 t-3-11 m-1-13 gc profile">
          <br></br> <br></br>
        </div>
        <div class="d-2-12 t-3-11 m-1-13 gc profile">
          {/* <Casestudylisting
            button_link="/sdad"
            button_text="Read Goodness Gracious's Story "
            img={csg}
            logo={csglogo}
            qoute={`"“There are a million apps out there, but using the right ones
            means we can serve more bagels
            and create a better experience for our customers.”"`}
          /> */}
          <Casestudylisting
            button_link="/case-studies/wild-pilates"
            button_text="Read Wild Pilates's Story "
            img={wp}
            logo={wplogo}
            qoute={`“With the right tools I can focus more energy on creating Pilates sessions and not doing admin.”`}
          />
          {/* <Casestudylisting
            button_link="/sdad"
            button_text="Read Good For Business's Story "
            img={gfb}
            logo={gfblogo}
            qoute={`“Using the right technology has been like a Swiss-Army knife on steroids!”`}
          />
          <Casestudylisting
            button_link="/sdad"
            button_text="Read Happy Business's Story "
            img={hb}
            logo={hblogo}
            qoute={`“Without The Right Tool I would’ve been able to implement all of this great software, now the business runs itself!”`}
          /> */}
        </div>
        <div class="d-2-12 t-3-11 m-1-13 gc profile">
          <br></br> <br></br>
        </div>
      </div>
    </div>
  );
}

class casestudies extends Component {
  render() {
    return (
      <div id="site_page">
        <Helmet>
          <meta
            name="description"
            content="With the right digital tools and experts in place, Laura says she finally has the confidence to scale up her business online."
          />
          <title>
            Hear what these Kiwi companies have to say about The Right Tool.
          </title>
        </Helmet>
        <FilterMainOld
          search={false}
          top="Case Studies"
          text={
            "Hear what these Kiwi companies have to say about The Right Tool."
          }
        />

        <OurStory />
      </div>
    );
  }
}

export default casestudies;
