import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import digitalBoostSmall from '../../assets/images/digitalBoostSmall.png'
import { industries } from '../../util/service'
import Filter from '../../components/filter/filter'

const Newfiltermain = (props) => {
  const [category, setCategory] = useState(props.homepagepass)
  const [indsutry, setIndsutry] = useState(props.homepagepass)
  let history = useHistory()

  useEffect(() => {
    const pattern = window.location.href.match(/tools\/(.*)/)[1]
    const industryfromURL = /[^/]*$/.exec(window.location.href)[0]
    const direct = pattern.substring(0, pattern.lastIndexOf('/'))
    setCategory(direct.substr(0, (direct + '?').indexOf('?')))
    setIndsutry(industryfromURL)
  }, [])

  const setFilter = (arg) => {
    if (arg === 'All') {
      history.push(`/tools/${category}`)
      return
    }
    history.push(`/tools/${category}/${arg}`)
  }
  const setFilterCa = (arg) => {
    const str = arg.toLowerCase()
    if (category === 'All') {
      history.push(`/tools/${str}`)
      return
    }
    history.push(`/tools/${str}/${indsutry}`)
  }

  return (
    <div
      class={`gc no-gap filtermain newbackground center_all flex ${category}`}
    >
      <div class="d-3-11 t-3-11 m-2-12 text_center">
        <h1>
          {`The Right `}
          <span class="background_red">{category.replace(/-/g, ' ')}</span>{' '}
          Tools
        </h1>
      </div>

      <div class="gov_logo">
        <a title="open external link" href="https://digitalboostalliance.nz//">
          <img
            src={digitalBoostSmall}
            alt="Small Business Digital Boost Logo"
          ></img>
        </a>
      </div>
      <div class="d-3-11 t-3-11 m-2-12 text_center filtermain_filter_container">
        <Filter
          id="1filterul"
          idlist="filterlist"
          query={industries}
          currentIndustry={indsutry}
          setFilter={setFilter.bind(this)}
        />
        {/* {window.location.href.indexOf("tools") > -1 ? (
          <div class="d-hide t-hide">
            <Filter
              id="main_categoryDrop"
              idlist="main_categoryDropList"
              query={categories}
              currentIndustry={category}
              setFilter={setFilterCa.bind(this)}
            />
          </div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  )
}

export default Newfiltermain
