import React, { useEffect, useState } from "react";

const Toggle = (props) => {
  const [active, setActive] = useState(props.activeApps);
  const ToggleFunction = (arg) => {
    if ((active && arg === "apps") || (!active && arg === "services"))
      return null;
    setActive(!active);
    props.change(!active);
  };

  return (
    <div class="gc no-gap center_all toggleListingTypes">
      <div
        class={`d-1-7 t-1-7 m-1-7 text_center ${
          active ? "" : "background_grey"
        }`}
      >
        <span
          onClick={() => ToggleFunction("apps")}
          class={`${active ? "" : "blue pointer "}`}
        >
          Apps
        </span>
      </div>
      <div
        class={`d-7-13 t-7-13 m-7-13 text_center ${
          !active ? "" : "background_grey"
        }`}
      >
        <span
          onClick={() => ToggleFunction("services")}
          class={`${!active ? "" : "blue pointer "}`}
        >
          {" "}
          Cloud Experts
        </span>
      </div>
    </div>
  );
};
export default Toggle;
