import React, { Component } from 'react'
import FilterMain from '../components/filter_main/filter_main'
import SmallCTA from '../components/small_cta/small_cta'
import image_4 from '../assets/images/laura-mohi-wild-pilates.png'
import { Helmet } from 'react-helmet'

function About (props) {
  return (
    <div class="gc  center_all content">
      <div class="d-2-7 t-3-11 m-2-12">
        <h2 class="padding_h2">
          This year Laura Mohi from Wild Pilates moved her physical studio to a
          completely online environment. "Funnily enough, it was our plan before
          Covid struck. Covid helped me accelerate my plans by 6-12 months."
        </h2>
      </div>
      <div class="d-7-12 t-hide m-hide text_center">
        <img src={image_4} style={{ width: '100%' }} alt="Wild Pilates" />
      </div>
    </div>
  )
}

function OurStory (props) {
  return (
    <div id="OurStory" class="gc  center_all content">
      <div class="d-2-12 t-3-11 m-2-12 ">
        <p class="main_p">
          Trialling the concept of hosting her wildly popular classes at home
          during Aotearoa's first lockdown, Laura says “I quickly realised I
          needed a whole suite of digital tools to make my business work
          smarter. But I had no idea where to start or what to use."
          <br></br>
          <br></br>
          After coming across the Digital Boost programme, Laura says "The Right
          Tool really helped me hone in on the specific tools I needed for the
          Pilates industry. I quickly pulled together a shopping cart of
          relevant digital apps which helped me launch my online studio. I now
          have solutions that allow me to host my online classes, manage
          subscriptions for my members, run my accounting and contact members
          via email".
          <br></br>
          <br></br>
          The final nudge Laura needed was getting help connecting the new tools
          with her website. "Through The Right Tool, I was thankfully able to
          find a cloud expert who was familiar with the digital tools I was
          using to help me bring it to help me get over that last hurdle."
          <br></br>
          <br></br>
          With the right digital tools and experts in place, Laura says she
          finally has the confidence to scale up her business online.
          <br></br>
          <br></br>
          See how Laura found all the tools she needed to make the move into the
          cloud easily over at{' '}
          <a class="blue pointer" href="https://therighttool.co.nz/">
            https://therighttool.co.nz/
          </a>
          .
        </p>
        {/* <div class="supportedBy">
          <span>Supported by</span>
          <a href="https://digitalboostalliance.nz//" title="open external link"><img src={logogovblack} alt="Small Business Digital Boost logo" /></a>
        </div> */}
      </div>
    </div>
  )
}

class toolfinder extends Component {
  render () {
    return (
      <div id="site_page">
        <Helmet>
          <meta
            name="description"
            content="With the right digital tools and experts in place, Laura says she finally has the confidence to scale up her business online."
          />
          <title>The Right Tools for taking Pilates to the cloud</title>
        </Helmet>
        <FilterMain
          search={false}
          top="Case Study"
          text={'The Right Tools for taking Pilates to the cloud'}
        />
        <About />
        <OurStory />
        <SmallCTA
          sub={'Looking for best of breed software for your business?'}
          button_text={'Check out the app directory'}
          button_link={'/directory/apps/accounting?n=Accounting'}
        />
      </div>
    )
  }
}

export default toolfinder
