import React, { useEffect, useState } from 'react'
import './search.scss'
import SearchItem from './searchItem'

const Result = (props) => {
  // const isvisible = this.props.visble
  const [data, setData] = useState(props.data)
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/apps/?search=${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setResults(...results, data)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
    fetch(process.env.REACT_APP_API_URL + `/services/?search=${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setResults(...results, data)
        }

        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <div className="gc no-gap" id="SearchResultContainer">
      <div class="d-2-12 t-2-12 m-2-12 ">
        <div class="searchResults">
          {loading ? (
            <svg class="splash-spinner" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          ) : (
            <div class="query">
              {' '}
              <span class="found">
                Found {results.length}{' '}
                {results.length > 1 ? 'results ' : 'result '}
                for
              </span>
              <span class="bold found"> '{data && data}'</span>
            </div>
          )}

          {results.map((x) => {
            return <SearchItem data={x} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Result
