import React, { useState } from 'react'
import './featuredindustry.scss'
import NewListing from '../../components/newlisting/newlisting'
import NewListingServices from '../../components/newlisting/newlisting_services'
import next_swiper from '../../assets/images/next_swiper.svg'
import prev_swiper from '../../assets/images/prev_swiper.svg'
//swiper
import Cookie from 'js-cookie'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper/core'
import 'swiper/swiper.min.css'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null
// install Swiper modules
SwiperCore.use([Autoplay])

const Featuredindustry = (props) => {
  const [services, setServices] = useState(
    props.data.featured_services ? props.data.featured_services : [],
  )

  const [apps, SetApps] = useState(
    props.data.featured_apps ? props.data.featured_apps : [],
  )
  const [LikedApps, setLikedApps] = useState([false])
  const [WishlistAdded, setWishlistAdded] = useState(false)

  const addFunction = (i) => {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: i.url,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let array = {
          listing: {
            url: data.listing,
          },
          url: data.url,
        }
        setWishlistAdded(true)
        setLikedApps(LikedApps.concat(array))
      })
      .catch((error) => {
        console.error(this.state.error)
      })
  }

  const removeFunction = (i) => {
    let urldelete = i.url
    for (let i = 0; i < LikedApps.length; i++) {
      if (LikedApps[i].listing.url === urldelete) {
        let url = LikedApps[i].url
        var array = [...LikedApps]

        array.splice(i, 1)
        setLikedApps(array)
        fetch(
          process.env.REACT_APP_API_URL + `/likes/apps/${url
            .split('/')
            .slice(-2)
            .join('/')}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${token}`,
            },
          },
        )
          .then((data) => {
            setWishlistAdded(true)
          })
          .catch((error) => {
            console.error(this.state.error)
          })
      }
    }
  }

  return (
    <>
      <div class="d-2-12 t-2-12 m-2-12 hr_parent">
        {' '}
        <hr />
      </div>

      <div class="d-2-12 t-2-12 m-2-12 text_center">
        <h2>Featured in your industry</h2>
      </div>

      <div class="d-1-2 t-1-2 m-1-2 action_swiper_button">
        <div className="review-swiper-button-prev ">
          <img src={prev_swiper} alt="previous arrow" />
        </div>
      </div>

      <div class="d-2-12 t-2-12 m-2-12 profile">
        <Swiper
          className="mySwiper"
          spaceBetween={80}
          slidesPerView={1}
          breakpoints={{
            950: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
          }}
          centeredSlides={true}
          initialSlide={1}
          loop={true}
          navigation={{
            nextEl: '.review-swiper-button-next',
            prevEl: '.review-swiper-button-prev',
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
        >
          {apps.map((app, i) => {
            return (
              <React.Fragment key={i}>
                <SwiperSlide>
                  <NewListing
                    addFunction={
                      WishlistAdded
                        ? removeFunction.bind(this)
                        : addFunction.bind(this)
                    }
                    //   }
                    wishlist={WishlistAdded}
                    data={app}
                    link={`/profile/app`}
                  />
                </SwiperSlide>
              </React.Fragment>
            )
          })}
        </Swiper>
      </div>
      <div class="d-12-13 t-12-13 m-12-13 action_swiper_button">
        <div className="review-swiper-button-next ">
          {' '}
          <img src={next_swiper} alt="previous arrow" />
        </div>
      </div>
      <div class="d-1-2 t-1-2 m-1-2 action_swiper_button">
        <div className="service-swiper-button-prev ">
          <img src={prev_swiper} alt="previous arrow" />
        </div>
      </div>
      <div class="d-2-12 t-2-12 m-2-12 profile">
        <Swiper
          spaceBetween={35}
          slidesPerView={1}
          grabCursor={false}
          navigation={{
            nextEl: '.service-swiper-button-next',
            prevEl: '.service-swiper-button-prev',
          }}
        >
          {services.map((app, i) => {
            return (
              <React.Fragment key={i}>
                <SwiperSlide>
                  <NewListingServices
                    data={app}
                    addFunction={
                      WishlistAdded
                        ? removeFunction.bind(this)
                        : addFunction.bind(this)
                    }
                    wishlist={WishlistAdded}
                    link={`/profile/cloud-expert`}
                  />
                </SwiperSlide>
              </React.Fragment>
            )
          })}
        </Swiper>
      </div>
      <div class="d-12-13 t-12-13 m-12-13 action_swiper_button">
        <div className="service-swiper-button-next ">
          {' '}
          <img src={next_swiper} alt="previous arrow" />
        </div>
      </div>
    </>
  )
}

export default Featuredindustry
