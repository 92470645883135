import React, { Component, useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import Button from '../components/button/button'
import Reviews from '../components/reviews/reviews'
import CTA from '../components/cta/cta'
import Offer from '../components/offer/offer'
import noImage from '../assets/images/noImage.png'
import ServiceCta from '../components/service_cta/service_cta'
import ReactModal from 'react-modal'
import {
  faHeart,
  faLongArrowAltLeft,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookie from 'js-cookie'
import { Helmet } from 'react-helmet'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

function ReviewContainer (props) {
  const reviews = props.reviews
  const [visibleBlogs, setVisibleBlogs] = useState(2)
  const handleOpenModal = props.handleOpenModal

  const handleClick = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 2)
  }

  const reviewMap = reviews.slice(0, visibleBlogs).map((review, i) => {
    return <Reviews data={review} />
  })
  return (
    <>
      <div class="d-4-12 t-2-12 m-2-12">
        <div class="split_between reviewContainer">
          <h4>Reviews</h4>
          <p class={reviews.length < 1 ? 'main_p' : 'hide'}>No reviews yet</p>
          <div class="pointer" onClick={token ? () => handleOpenModal() : ''}>
            {' '}
            <Button
              text={token ? 'Write a Review' : 'Sign up to leave a Review'}
              color="white"
              link={
                token
                  ? null
                  : 'https://admin.therighttool.co.nz/auth/login?returnTo=/'
              }
            />{' '}
          </div>
        </div>
      </div>
      <div class="d-4-12 t-2-12 m-2-12 gc">{reviewMap}</div>
      <div
        onClick={handleClick}
        class="d-4-12 t-2-12 m-2-12 text-center flex center_all padding20"
      >
        <div className={`${reviews.length <= visibleBlogs ? 'hide' : ''} `}>
          {' '}
          {reviews.length > 2 ? (
            <Button
              text={'Show more'}
              color="white"
              className={`${reviews.length <= visibleBlogs ? 'hide' : ''} `}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

class profileapps extends Component {
  constructor (props) {
    super(props)
    this.state = {
      industries: [],
      data: [],
      reviews: [],
      industries_served: [],
      showModal: false,
      review_rating: 0,
      loading: true,
      sendingReview: false,
      sendingClaim: false,
      claimSent: false,
      admin: false,
      reviewSent: false,
      app_likes: [],
      wishlistAdded: false,
      claimshowModal: false,
      review_content: '',
      url: '',
      errorsreview: [],
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleclaimOpenModal = this.handleclaimOpenModal.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleclaimCloseModal = this.handleclaimCloseModal.bind(this)
    this.fetchListing = this.fetchListing.bind(this)
  }

  fetcher () {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ app_likes: this.state.data })
        for (let i = 0; i < this.state.app_likes.length; i++) {
          const element = this.state.app_likes[i]
          if (element.listing.url === this.state.data.url) {
            this.setState({ wishlistAdded: true, url: element.url })
          }
        }
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  handleclaimOpenModal () {
    this.setState({ claimshowModal: true })
  }

  handleOpenModal () {
    this.setState({ showModal: true })
  }

  handleRating (rating) {
    this.setState({ review_rating: rating })
  }

  handleCloseModal () {
    this.setState({ showModal: false })
  }

  handleclaimCloseModal () {
    this.setState({ claimshowModal: false })
  }

  handleFormChange (evt) {
    const value = evt.target.value
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    })
  }

  componentWillMount () {
    if (this.props.match.params.handle) {
      this.setState({ name: this.props.match.params.handle }, function () {
        fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({ app_likes: data })
            this.fetchListing()
          })
          .catch((error) => {
            this.setState({ error: error })
            console.log(this.state.error)
          })
      })
    }
  }

  fetchListing = (e) => {
    fetch(process.env.REACT_APP_API_URL + `/apps/${this.state.name}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.created_by ===
          process.env.REACT_APP_API_URL +
          '/users/e79536e6-0747-4bfc-b68d-bc1d8bb86f2b/'
        ) {
          this.setState({ admin: true })
        }
        this.setState({
          loading: false,
          data: data,
          reviews: data.reviews,
          industries: data.subcategories,
        })

        for (let i = 0; i < this.state.app_likes.length; i++) {
          const element = this.state.app_likes[i]
          if (element.listing.url === data.url) {
            this.setState({ wishlistAdded: true, url: element.url })
          }
        }
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  postClaim = (e) => {
    this.setState({ claimshowModal: false })
    fetch(process.env.REACT_APP_API_URL + `/claims/apps/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: this.state.data.url,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ sendingClaim: false, claimSent: true })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  postReview = (e) => {
    this.setState({ sendingReview: true })
    fetch(process.env.REACT_APP_API_URL + `/reviews/apps/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: this.state.data.url,
        rating: this.state.review_rating,
        comment: this.state.review_content,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let dataarry
        if (data.url) {
          this.setState({ sendingReview: false, reviewSent: true })
        } else {
          for (const [key, value] of Object.entries(data)) {
            dataarry.push(`${key}: ${value}`)
          }
          this.setState({
            errorsreview: dataarry,
          })
        }
      })
      .catch((error) => {
        this.setState({ errorreview: error })
        console.log(this.state.error)
      })
  }

  goBack () {
    window.history.back()
  }

  removeFromToolkit = (i) => {
    let url = this.state.url
    fetch(
      process.env.REACT_APP_API_URL + `/likes/apps/${url
        .split('/')
        .slice(-2)
        .join('/')}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      },
    )
      .then((data) => {
        this.setState({ wishlistAdded: false })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  saveListing = (e) => {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: this.state.data.url,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ wishlistAdded: true })
        this.fetcher()
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  render () {
    const {
      reviews,
      data,
      claimSent,
      sendingClaim,
      loading,
      showModal,
      claimshowModal,
      admin,
      wishlistAdded,
      reviewSent,
      sendingReview,
    } = this.state
    var handleOpenModal = this.handleOpenModal
    if (loading) {
      return (
        <div id="profile">
          <div class="gc no-gap profileHeader">
            <div class="d-2-12 t-2-12 m-2-12 ">
              <a onClick={this.goBack}>
                <div class="goback">
                  <span class="white pointer">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go Back
                  </span>
                </div>
              </a>
            </div>
            <div class="d-2-12 t-2-12 m-1-13 ">
              <div id="profilePictureContainer" class="d-2-12 t-2-12 m-1-13 ">
                <div class="profilepicture">
                  <img
                    src={data.logo ? data.logo : noImage}
                    alt="logo"
                    itemprop="logo"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div class="gc no-gap splashscreen">
            <div class="d-2-12 t-3-11 m-1-13 ">
              <svg class="splash-spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div id="profile" itemscope itemtype="http://schema.org/Organization">
          {' '}
          <Helmet>
            <title>
              {data.name} - The Right Tool - Aotearoa’s leading app marketplace
            </title>
            <meta
              name="description"
              content={`Read real reviews for ${data.name} - ${data.short_description} `}
            />
          </Helmet>
          <a class="skiplink" href="#profile">
            Skip To Content
          </a>
          <ReactModal
            className="modal gc claimModal"
            isOpen={this.state.claimshowModal}
            contentLabel="Minimal Modal Example"
            closeTimeoutMS={500}
          >
            <div class="form_container d-1-13 ">
              <div class="review_modal_inner">
                <div></div>
                <div onClick={this.handleclaimCloseModal} class="pointer fasvg">
                  <button onClick={this.handleclaimCloseModal}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            </div>

            {!sendingClaim && !claimSent ? (
              <>
                <div class="form_container d-1-13 ">
                  <h4>Are you sure?</h4>
                  <span class="darkgrey">
                    Is this your app? Send a claim request to gain access.
                  </span>
                </div>
                {' '}
                <div class="form_container d-1-13">
                  <div class="split_between">
                    <div class="pointer" onClick={this.handleclaimCloseModal}>
                      <Button text={'Cancel'} color="grey" />
                    </div>
                    <div class="pointer" onClick={this.postClaim}>
                      <Button text={'Confirm'} color="blue" />
                    </div>
                  </div>
                </div>
                {' '}
              </>
            ) : null}
          </ReactModal>
          <ReactModal
            className="modal gc "
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            closeTimeoutMS={500}
          >
            <div class="form_container d-1-13 ">
              <div class="review_modal_inner">
                <div></div>
                <div onClick={this.handleCloseModal} class="pointer fasvg">
                  <button onClick={this.handleCloseModal}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            </div>
            {reviewSent ? (
              <h4 class="thankyou">Thank you for the review</h4>
            ) : null}
            {sendingReview ? (
              <svg class="splash-spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            ) : null}
            {!sendingReview && !reviewSent ? (
              <>
                <div class="form_container d-1-13 ">
                  <img
                    src={data.logo ? data.logo : noImage}
                    alt="The listing logo"
                  ></img>
                  <h4>{data.name} Review</h4>
                  <span class="darkgrey">
                    Leave a review - please note these go through an approval
                    process.
                  </span>

                  <div>
                    <ReactStars
                      value={this.state.review_rating}
                      onChange={(rating) => this.handleRating(rating)}
                      size="30"
                      activeColor={'#FAAA00'}
                      color={'#767676'}
                    />
                  </div>
                  <div>
                    <span class="subline">Review</span>
                    <textarea
                      value={this.review_content}
                      onChange={this.handleFormChange}
                      name="review_content"
                      // class={errors.username ? "errorinputfield" : ""}
                    />
                  </div>
                  <div class="errorbox">
                    {' '}
                    {this.state.errorsreview.map((x, i) => (
                      <li>{x}</li>
                    ))}{' '}
                  </div>
                </div>
                {' '}
                <div class="form_container d-1-13">
                  <div class="split_between">
                    <div class="pointer" onClick={this.handleCloseModal}>
                      <button onClick={this.handleCloseModal}>
                        <Button text={'Cancel'} color="grey" />
                      </button>
                    </div>
                    <div class="pointer">
                      <button onClick={this.postReview}>
                        <Button
                          classname="sendmodaltag"
                          text={'Confirm'}
                          color="blue"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {' '}
              </>
            ) : null}
          </ReactModal>
          <div class="gc no-gap profileHeader">
            <div class="d-2-12 t-2-12 m-2-12 ">
              <a onClick={this.goBack}>
                <div class="goback">
                  <span class="white pointer">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} /> Go Back
                  </span>
                </div>
              </a>
            </div>
            <div id="profilePictureContainer" class="d-2-12 t-2-12 m-1-13 ">
              <div class="profilepicture">
                <img
                  src={data.logo ? data.logo : noImage}
                  alt="The listing logo"
                  itemprop="logo"
                ></img>
              </div>
            </div>
          </div>
          {data.special_offers.length > 1 && (
            <Offer data={data.special_offers} />
          )}
          <div
            class={`gc no-gap ${
              data.special_offers.length > 0 ? 'specialoffercontent' : 'content'
            } opacityfade`}
          >
            <div class="d-4-12 t-2-12 m-2-12 profile_mobile">
              <span class="industry">
                {' '}
                {this.state.industries.map((industry, i) => {
                  let length = this.state.industries.length
                  if (length === i + 1) {
                    return `${industry.name}`
                  } else {
                    return `${industry.name}, `
                  }
                })}
              </span>
              <div class="profile_position">
                <div class="heartPosition">
                  <h1 itemprop="name" class="inline-block ">
                    {data.name}
                  </h1>
                  <div class={`${token ? null : 'hide'}`}>
                    <FontAwesomeIcon
                      className={
                        token && wishlistAdded
                          ? 'pointer hearticon inline-block  heartFilledYes'
                          : 'pointer heartOutline'
                      }
                      icon={wishlistAdded ? faHeart : farHeart}
                      onClick={
                        wishlistAdded
                          ? this.removeFromToolkit
                          : this.saveListing
                      }
                    />
                  </div>
                </div>
                <div class="d-hide t-hide">
                  <ReactStars
                    value={data.rating ? data.rating : 0}
                    onChange={(rating) => this.handleRating(rating)}
                    size="30"
                    edit={false}
                    activeColor={'#FAAA00'}
                    color={'#767676'}
                  />
                  <span class="darkgrey"> {reviews.length} Reviews</span>
                </div>
                <div id="profileHeaderBtns" class="btn_container">
                  {data.free_trial ? (
                    <Button
                      classname={'freetrial'}
                      text={'Start free trial'}
                      color="green"
                      link={data.free_trial}
                    />
                  ) : (
                    ''
                  )}
                  {admin && token && !claimSent ? (
                    <span
                      onClick={token ? () => this.handleclaimOpenModal() : ''}
                      class="pointer subline"
                    >
                      Claim this listing
                    </span>
                  ) : (
                    ''
                  )}
                  {admin && !claimSent && !token ? (
                    <span class="pointer subline">
                      <a
                        href={`https://admin.therighttool.co.nz/auth/login?returnTo=${window.location.pathname}`}
                      >
                        Sign up to claim this listing
                      </a>
                    </span>
                  ) : (
                    ''
                  )}
                  {admin && token && claimSent ? (
                    <span class="pointer subline">Claim request sent</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div class="m-hide starsnew">
                <ReactStars
                  value={data.rating ? data.rating : 0}
                  onChange={(rating) => this.handleRating(rating)}
                  size="30"
                  edit={false}
                  activeColor={'#FAAA00'}
                  color={'#767676'}
                />
                <span class="darkgrey"> {reviews.length} Reviews</span>
              </div>
            </div>
            <div class="d-4-12 t-2-12 m-2-12 inner">
              <h4>About</h4>

              <p itemprop="description" class="main_p">
                {data.long_description}
              </p>
              <p class="main_p pricing_p">{data.pricing}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data.link}
                itemprop="website"
                class="blue main_p freetrial"
              >
                Visit Website
              </a>
            </div>
            <ReviewContainer
              handleOpenModal={handleOpenModal.bind(this)}
              reviews={reviews}
            />
          </div>
          <ServiceCta
            text={`Meet cloud experts who can help you with ${data.name}`}
            sub={
              'Find cloud experts that can get you started on any of these apps. So you can get the help when you need it.'
            }
            button_text={'View More Cloud Experts'}
            button_link={''}
          />
          <CTA
            text={'Want your app in the directory?'}
            sub={'Get your app to show for kiwi businesses today.'}
            button_text={'List My App'}
            button_link={'/list-an-app'}
          />
        </div>
      )
    }
  }
}

export default profileapps
