import React, { Component } from 'react'
import './menu.scss'
import { withRouter } from 'react-router-dom'
import logowhite from '../../assets/images/logo_white.png'
import logoblue from '../../assets/images/logo_blue.png'
import searchIconClose from '../../assets/images/searchIconClose.png'
import searchIconBlack from '../../assets/images/search_icon.png'
import searchIconWhite from '../../assets/images/search_icon_white.png'
import heartFilledWhite from '../../assets/images/heartFilledWhite.png'
import heartFilledBlack from '../../assets/images/heartFilledBlack.png'
import noImage from '../../assets/images/noImage.png'
import Filter from '../../components/filter/filter'
import Cookie from 'js-cookie'
import Search from '../search/search'
import { faBars, faHeart, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { categories } from '../../util/service'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

const secure = window.location.protocol === 'https'
const queryString = require('query-string')

class SearchOverlay extends Component {
  constructor (props) {
    super(props)
  }

  componentDidUpdate () {
    if (this.props.visble) {
      document.getElementById('searchInputField').focus()
    }
  }

  render () {
    const isvisible = this.props.visble

    return (
      <div
        className={`SearchOverlay ${
          isvisible ? 'fadeinsearch' : 'fadeoutsearch'
        }`}
      >
        <Search />
      </div>
    )
  }
}

class Wishlist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      toggle: false,
      category: true,
      loading: true,
      app_likes: [],
      service_likes: [],
      toolbox: [],
    }
  }

  fetcher () {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ app_likes: data, toolbox: data })
        this.setState({ toolbox: this.state.app_likes, loading: false })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  fetcherServices () {
    fetch(process.env.REACT_APP_API_URL + `/likes/services/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ service_likes: data })
        this.setState({ toolbox: this.state.service_likes, loading: false })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  componentDidMount () {
    this.setState({ loading: true })
    this.fetcher()
    document.getElementById('eventlisten').addEventListener('click', (e) => {
      const that = this
      if (
        e.target.parentNode.classList.contains('apper') ||
        e.target.parentNode.classList.contains('heartFilledWhite')
      ) {
        if (this.state.category) {
          this.setState({ loading: true })

          setTimeout(function () {
            that.setState({
              app_likes: that.props.firstload,
              toolbox: that.props.firstload,
              loading: false,
            })
          }, 700)
        }
      }
    })
  }

  Toggle = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }))
    if (this.state.toggle) {
      this.fetcher()
      this.setState({ category: true, loading: true })
    } else {
      this.fetcherServices()
      this.setState({ category: false, loading: true })
    }
  }
  removeFromToolkitServices = (i) => {
    fetch(
      process.env.REACT_APP_API_URL + `/likes/services/${i
        .split('/')
        .slice(-2)
        .join('/')}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `${token}`,
        },
      },
    )
      .then((data) => {
        this.fetcherServices()
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  removeFromToolkit = (i) => {
    fetch(
      process.env.REACT_APP_API_URL + `/likes/apps/${i
        .split('/')
        .slice(-2)
        .join('/')}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      },
    )
      .then((data) => {
        this.fetcher()
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  render () {
    const { toolbox, loading, toggle, category } = this.state

    return (
      <div id="toolbox">
        <div class="gc no-gap center_all toggleListingTypes_wishlist">
          <div
            class={`d-1-7 t-1-7 m-1-7 text_center appper ${
              !toggle ? '' : 'background_grey'
            }`}
          >
            <span
              onClick={this.Toggle}
              class={`${!toggle ? 'dark' : 'blue pointer '}`}
            >
              Apps
            </span>
          </div>

          <div
            class={`d-7-13 t-7-13 m-7-13 text_center cloud ${
              toggle ? '' : 'background_grey'
            }`}
          >
            <span
              onClick={this.Toggle}
              class={`${toggle ? 'dark' : 'blue pointer '}`}
            >
              Cloud Experts
            </span>
          </div>
        </div>
        <div
          id="toolboxItems"
          class={`gc no-gap center_all toggleListingTypes_wishlist ${
            loading ? 'center_toolbox' : ''
          }`}
        >
          {loading ? (
            <svg class="splash-spinner" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          ) : null}
          {toolbox.map((like, i) => {
            if (loading) {
              return null
            }
            return (
              <React.Fragment key={i}>
                <div
                  data-key={like.url}
                  class={`d-1-4 t-1-4 m-1-4 toolbox_item`}
                >
                  <img
                    src={like.listing.logo ? like.listing.logo : noImage}
                    alt="The Listing logo"
                  ></img>
                </div>
                <div
                  data-key={like.url}
                  class={`d-4-13 t-4-13 m-4-13 toolbox_item remove_toolkit`}
                >
                  <div>
                    <span>
                      {category
                        ? like.listing.subcategories.map((industry, i) => {
                          let length = like.listing.subcategories.length
                          if (length === i + 1) {
                            return `${industry.name}`
                          } else {
                            return `${industry.name}, `
                          }
                        })
                        : null}
                    </span>
                    <a
                      href={`/profile/${
                        category ? 'app' : 'service'
                      }/${like.listing.url.split('/').slice(-2).join('/')}`}
                    >
                      <h6 class="blue">{like.listing.name}</h6>
                    </a>{' '}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={
                        category
                          ? () => this.removeFromToolkit(like.url)
                          : () => this.removeFromToolkitServices(like.url)
                      }
                    />{' '}
                  </div>
                </div>
              </React.Fragment>
            )
          })}
          {toolbox.length === 0 && !loading ? (
            <div class={`d-1-13 t-3-11 m-1-13 text_center`}>
              <span class="dark">Empty.</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

class menu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hidemenu: false,
      wishlist_open: false,
      open: false,
      initialData: [],
      searchIsOpen: false,
      category: [],
    }
    this.setFilter = this.setFilter.bind(this)
  }

  fetcher () {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ initialData: data })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  handleClick = () => {
    this.fetcher()
    this.setState({
      wishlist_open: (this.wishlist_open = !this.wishlist_open),
    })
  }

  handleClickMenuMobile = () => {
    this.setState({
      open: (this.open = !this.open),
    })
  }

  handleOpenSearch = () => {
    document.getElementsByTagName('body')[0].classList.toggle('overflowy')

    this.setState({
      searchIsOpen: (this.searchIsOpen = !this.searchIsOpen),
    })
  }

  componentDidMount () {
    const self = this
    if (token) {
      this.fetcher()
    }
    // const pattern = window.location.href.match(/tools\/(.*)/)[1];
    // const direct = pattern.substring(0, pattern.lastIndexOf("/"));

    // this.setState({ category: direct.substr(0, (direct + "?").indexOf("?")) });

    window.addEventListener('scroll', function () {
      var elementTarget = 100
      if (window.scrollY > elementTarget) {
        self.setState({ hidemenu: true })
        self.setState({ wishlist_open: false, open: false })
      } else {
        self.setState({ hidemenu: false })
      }
    })
  }

  componentWillMount () {
    let search = window.location.search
    const parsed = queryString.parse(search)
  }

  cookieMonster = () => {
    Cookie.set(
      'AUTH_TOKEN',
      this.state.token,
      { path: '/' },
      undefined,
      secure,
    )
    window.location.href = '/'
  }

  setFilter (arg) {
    const pattern = window.location.href.match(/tools\/(.*)/)[1]

    const current = pattern.substring(0, pattern.lastIndexOf('/'))

    let industryfromURL
    if (/[^/]*$/.exec(window.location.href)[0].length > 3) {
      industryfromURL = /[^/]*$/.exec(window.location.href)[0]
    }
    const newarg = arg.toLowerCase()
    if (current === newarg) return

    window.location.href = `/tools/${newarg}/${
      industryfromURL ? industryfromURL : ''
    }`
    // this.props.history.push(

    // );
    // window.location.href =;
  }

  render () {
    const { hidemenu, wishlist_open, open, initialData } = this.state
    const transparent = this.props.transparent
    let homelogo = transparent ? logowhite : logoblue
    let searchIcon = transparent ? searchIconWhite : searchIconBlack
    if (token) {
      return (
        <div
          id="menu"
          class={`gc no-gap ${transparent ? '' : 'menu_background_white'}`}
        >
          <div class="d-2-2 t-2-2 m-3-5 headerbar-container aboveMenu">
            <a href="/" title="return to the homepage">
              <img
                class="header-logo"
                src={!this.state.searchIsOpen ? homelogo : logoblue}
                alt="The Right Tool Logo"
              ></img>
            </a>
            {window.location.href.indexOf('tools') > -1 ? (
              <div class="m-hide">
                <Filter
                  id="main_categoryDrop"
                  idlist="main_categoryDropList"
                  query={categories}
                  currentIndustry={this.state.category}
                  setFilter={this.setFilter}
                />
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            className={`d-5-12 t-5-12 m-hide headerbar-container header-signup`}
          >
            <div className={`pointer headerbar-container`}>
              <div id="eventlisten" class="header-actions pop_up_container">
                <FontAwesomeIcon
                  className={
                    transparent ? 'heartFilledWhite' : 'heartFilledBlack'
                  }
                  icon={faHeart}
                  onClick={() => this.handleClick()}
                />
                <div
                  id="profileDropdown"
                  className={`popupmodal ${
                    wishlist_open ? 'active' : 'inactive'
                  }`}
                >
                  <Wishlist firstload={initialData} />
                </div>
              </div>

              <div class="header-actions">
                <a href="https://admin.therighttool.co.nz/">
                  <span>Account</span>
                </a>
              </div>

              <div class="header-actions">
                <a href="https://admin.therighttool.co.nz/logout">
                  <span>Sign Out</span>
                </a>
              </div>
              <div class="header-actions header-signup">
                <a class="signuptag" href="/free-tool-finder">
                  <span>Free Tool Finder</span>
                </a>
              </div>
              <div class="header-actions aboveMenu">
                <img
                  onClick={() => this.handleOpenSearch()}
                  src={!this.state.searchIsOpen ? searchIcon : searchIconClose}
                  class="searchIcon inline-block"
                />
              </div>
            </div>
          </div>
          <SearchOverlay visble={this.state.searchIsOpen} />
          <div
            className={`d-hide t-hide m-8-8 headerbar-container header-signup`}
          >
            <div className={`pointer headerbar-container`}>
              <div class="header-actions pop_up_container">
                <img
                  onClick={this.handleClick}
                  class=" hearticon inline-block"
                  src={transparent ? heartFilledWhite : heartFilledBlack}
                  alt="heart"
                />
                <div
                  id="profileDropdown"
                  className={`popupmodal ${
                    wishlist_open ? 'active' : 'inactive'
                  }`}
                >
                  <Wishlist firstload={initialData} />
                </div>
              </div>
            </div>
          </div>
          <div className={`d-hide t-hide m-9-12  headerbar-container `}>
            <div className={'headerbar-container'}>
              <div
                className={`pointer headerbar-container`}
                onClick={this.handleClickMenuMobile}
              >
                <FontAwesomeIcon
                  className={
                    transparent ? 'heartFilledWhite' : 'heartFilledBlack'
                  }
                  icon={open ? faTimes : faBars}
                />
              </div>

              <div
                id="landingDropdownLoggedin"
                className={`menuppopupmodal ${
                  open ? 'active' : 'inactiveMenu'
                }`}
              >
                <a href="/free-tool-finders">
                  <div class="header-actions">
                    <span>Free Tool Finder</span>
                  </div>
                </a>
                <a href="https://admin.therighttool.co.nz/">
                  <div class="header-actions">
                    <span>Account</span>
                  </div>
                </a>
                <a href="https://admin.therighttool.co.nz/logout">
                  <div class="header-actions">
                    <span>Sign Out</span>
                  </div>
                </a>
              </div>
              <div class="header-actions aboveMenu">
                <img
                  onClick={() => this.handleOpenSearch()}
                  src={!this.state.searchIsOpen ? searchIcon : searchIconClose}
                  class="searchIcon inline-block"
                />
              </div>
            </div>
          </div>

          <a class="skiplink" href="#footer">
            Skip To Footer
          </a>
        </div>
      )
    } else {
      return (
        <div
          id="menu"
          class={`gc no-gap ${transparent ? '' : 'menu_background_white'}`}
        >
          <a class="skiplink" href="#footer">
            Skip to Footer
          </a>
          <div class="d-2-3 m-3-5 headerbar-container aboveMenu">
            <a href="/" title="return to the homepage">
              <img
                class="header-logo"
                src={!this.state.searchIsOpen ? homelogo : logoblue}
                alt="The Right Tool logo"
              ></img>
            </a>
            {window.location.href.indexOf('tools') > -1 ? (
              <div class="m-hide">
                <Filter
                  id="main_categoryDrop"
                  idlist="main_categoryDropList"
                  query={categories}
                  currentIndustry={this.state.category}
                  setFilter={this.setFilter}
                />
              </div>
            ) : (
              ''
            )}
          </div>

          <div
            className={`d-4-12 t-4-12 m-hide headerbar-container header-signup`}
          >
            <div className={`pointer headerbar-container`}>
              {/* <a href="/case-studies">
                <div class="header-actions">
                  <span>Case Studies</span>
                </div>
              </a> */}

              <div class="header-actions">
                <a href="https://admin.therighttool.co.nz/auth/login">
                  <span>Sign In</span>
                </a>
              </div>
              <div class="header-actions">
                <a href="https://admin.therighttool.co.nz/auth/registration">
                  <span>Create Account</span>
                </a>
              </div>
              <div class="header-actions header-signup">
                <a class="signuptag" href="/free-tool-finder">
                  <span>Free Tool Finder</span>
                </a>
              </div>
              <div class="header-actions aboveMenu">
                <img
                  onClick={() => this.handleOpenSearch()}
                  src={!this.state.searchIsOpen ? searchIcon : searchIconClose}
                  class="searchIcon inline-block"
                />
              </div>
            </div>
          </div>
          <SearchOverlay visble={this.state.searchIsOpen} />
          <div
            className={`d-hide t-hide m-6-9 headerbar-container header-signup`}
          >
            <div className={`pointer headerbar-container`}>
              <div class="header-actions">
                <a href="https://admin.therighttool.co.nz/auth/login">
                  <span>Sign In</span>
                </a>
              </div>
            </div>
          </div>
          <div className={`d-hide t-hide m-9-12  headerbar-container `}>
            <div className={'headerbar-container'}>
              <div
                className={`pointer headerbar-container`}
                onClick={this.handleClickMenuMobile}
              >
                <FontAwesomeIcon
                  className={
                    transparent ? 'heartFilledWhite' : 'heartFilledBlack'
                  }
                  icon={open ? faTimes : faBars}
                />
              </div>
              <div class="header-actions aboveMenu">
                <img
                  onClick={() => this.handleOpenSearch()}
                  src={!this.state.searchIsOpen ? searchIcon : searchIconClose}
                  class="searchIcon inline-block"
                />
              </div>
              <div
                id="landingDropdownLoggedin"
                className={`menuppopupmodal ${
                  open ? 'active' : 'inactiveMenu'
                }`}
              >
                <a href="/case-studies">
                  <div class="header-actions">
                    <span>Case Studies</span>
                  </div>
                </a>
                <a
                  class="signuptag"
                  href="https://admin.therighttool.co.nz/auth/registration"
                >
                  <div class="header-actions">
                    <span>Create Account</span>
                  </div>
                </a>
                <a href="/free-tool-finder">
                  <div class="header-actions">
                    <span>Free Tool Finder</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(menu);
