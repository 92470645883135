import React from "react";
import "./offer.scss";
import gift from "../../assets/images/gift.png";

import Button from "../button/button";

const Offer = props => {
  const data = props.data[0];
  return (
    <div id="offer" className="gc no-gap">
      <div class="d-4-12 t-2-12 m-2-12 offerwrapper  flex">
        <div class="offerinfo center_all">
          <img src={gift} />
          <div>
            <h5>{data.title}</h5>
            <span>{data.description}</span>
          </div>
        </div>
        <Button text={data.label} color="dblue" link={data.link} />
      </div>
    </div>
  );
};

export default Offer;
