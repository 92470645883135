import React, { Component } from "react";
import FilterMainOld from "../components/filter_main/filtermain_old";
import SmallCTA from "../components/small_cta/small_cta";
import { Helmet } from "react-helmet";
function Terms(props) {
  return (
    <div class="gc no-gap center_all content">
      <div class="d-3-11 t-3-11 m-2-12 ">
        <p class="termTitle">APPLICATION OF TERMS</p>

        <p>
          These Terms apply to your use of The Right Tool. By accessing and
          using The Right Tool:
        </p>

        <p>
          you accept and agree to be bound by these Terms; and
          <br />
          where your access and use is on behalf of another person (e.g. a
          company), you confirm that you are authorised to, and do in fact,
          agree to these Terms on that person’s behalf and that, by agreeing to
          these Terms on that person’s behalf, that person is bound by these
          Terms.
        </p>

        <p>
          If you do not agree to these Terms, you are not authorised to access
          and use The Right Tool, and you must immediately stop doing so.
        </p>

        <p class="termTitle">CHANGES</p>

        <p>
          We may change these Terms at any time by updating them on The Right
          Tool. Unless stated otherwise, any change takes effect immediately.
          You are responsible for ensuring you are familiar with the latest
          Terms. By continuing to access and use The Right Tool, you agree to be
          bound by the changed Terms.
        </p>

        <p>
          We may change, suspend, discontinue, or restrict access to, The Right
          Tool without notice or liability.
          <br />
          Terms and Conditions last updated: These Terms were last updated on
          [insert].
        </p>

        <p></p>

        <p class="termTitle">DEFINITIONS AND INTERPRETATION</p>

        <p>Definitions: In these Terms:</p>

        <p>
          Advertiser means:
          <br />
          a person who has set up an account with The Right Tool to list its
          Application and offers that Application to The Right Tool users; or
          <br />
          a Service Provider who has set up an account with The Right Tool to
          list its Service and offers that Service to The Right Tool users
          <br />
          Application means an application (or app), offering a digital tool,
          software, product or technology designed to run on an electronic
          device such as a computer, mobile or tablet, downloaded from an
          application distribution platform or store, or subscribed to through
          an Advertiser directly
          <br />
          Content means content, data, and information (including personal
          information) that is owned, held, used or created by you or on your
          behalf, and that is then stored, transmitted via, input into or
          displayed via The Right Tool including, in the case of an Advertiser,
          all content, data and information uploaded into The Right Tool by the
          Advertiser when creating a Listing <br />
          Features has the meaning set out in clause 7.2
          <br />
          Fees means the fees payable to us in respect of The Right Tool by a
          Service Provider offering a Service as set out on our
          list-your-service fees page at [insert page] (including GST)
          <br />
          Intellectual Property Rights includes copyright and all rights
          anywhere in the world conferred under statute, common law or equity
          relating to inventions (including patents), registered and
          unregistered trade marks and designs, circuit layouts, data and
          databases, confidential information, know-how, and all other rights
          resulting from intellectual activity. Intellectual Property has a
          consistent meaning, and includes any enhancement, modification or
          derivative work of the Intellectual Property
          <br />
          Listing means an advertisement or post by an Advertiser on The Right
          Tool offering its Application or Service to The Right Tool users
          <br />
          Loss includes loss of profits, savings, revenue or data, and any other
          claim, damage, loss, liability and cost, including legal costs on a
          solicitor and own client basis
          <br />
          Objectionable includes being objectionable, defamatory, obscene,
          harassing, threatening or unlawful, in any way
          <br />
          Personal information means information about an identifiable, living
          person
          <br />
          Service means an operational or business integration service provided
          by a Service Provider
          <br />
          Service Provider means an Advertiser who has a Service Listing on The
          Right Tool as verified by us in our sole discretion and notified to
          the Advertiser
          <br />
          Subscription Period means the subscription period a Service Provider
          selects for its The Right Tool account
          <br />
          Terms means these terms and conditions titled The Right Tool platform
          terms of use
          <br />
          The Right Tool means the online portal available on the Website <br />
          Underlying Systems means the IT solutions, systems and networks
          (including software and hardware) used to provide The Right Tool,
          including any third party solutions, systems and networks
          <br />
          User ID means a unique name and/or password allocated to you to allow
          you to access certain features of The Right Tool
          <br />
          We, us or our means Bad Ideas Limited, company number 7979822
          <br />
          Website means therighttool.co.nz
          <br />
          You means you or, if clause 1.2c applies, both you and the other
          person on whose behalf you are acting
        </p>

        <p>
          In these Terms: <br />
          clause and other headings are for ease of reference only and do not
          affect the interpretation of these Terms;
          <br />
          words in the singular include the plural and vice versa; and
          <br />
          a reference to:
          <br />
          a person includes an individual, a body corporate, an association of
          persons (whether corporate or not), a trust, a government department,
          or any other entity;
          <br />
          including and similar words do not imply any limit; and <br />a
          statute includes references to regulations, orders or notices made
          under or in connection with the statute or regulations and all
          amendments, replacements or other changes to any of them.
        </p>

        <p></p>

        <p class="termTitle">ABOUT THE RIGHT TOOL</p>

        <p>
          The Right Tool provides a searchable online directory of Applications
          and Services where Advertisers can create Listings to promote their
          Applications and Services to New Zealand business owners.
        </p>

        <p>
          We act as an intermediary for The Right Tool users to transact and are
          not a party to any an agreement to purchase, use or provide the
          Applications or Services advertised on The Right Tool. When a user
          purchases or uses an Application or Service and an Advertiser agrees
          to provide that Application or Service, they are entering into an
          agreement directly with each other.
        </p>

        <p>
          Any interaction between you and another The Right Tool user, including
          any agreement entered into between you and another The Right Tool
          user, is a matter directly between you and them only. Other than our
          obligations set out in these Terms, we are not liable to you for any
          failure by any The Right Tool user to comply with these Terms or any
          other legal obligation.
        </p>

        <p>
          We will use reasonable efforts to provide The Right Tool in accordance
          with these Terms and New Zealand law.
          <br />
          Our provision of The Right Tool to you is non-exclusive. Nothing in
          these Terms prevents us from providing The Right Tool to any other
          person.
        </p>

        <p>
          Subject to clause 4.7, we will use reasonable efforts to ensure The
          Right Tool is available on a 24/7 basis. However, it is possible that
          on occasion The Right Tool may be unavailable to permit maintenance or
          other development activity to take place, or due to an event that is
          beyond our reasonable control.
        </p>

        <p>
          Through the use of web services and APIs, The Right Tool may interact
          with a range of third party service features. We do not make any
          warranty or representation on the availability or performance of those
          features. Without limiting the previous sentence, if a third party
          feature provider ceases to provide that feature or ceases to make that
          feature available on reasonable terms, we may cease to make available
          that feature to you. To avoid doubt, if we exercise our right to cease
          the availability of a third party feature, you are not entitled to any
          refund, discount or other compensation.
        </p>

        <p></p>

        <p class="termTitle">USE OF THE RIGHT TOOL</p>

        <p>You must:</p>

        <p>
          use The Right Tool in accordance with these Terms solely for:
          <br />
          your internal business purposes including:
          <br />
          if you are an Advertiser, hosting your Listings to market your
          Application or Service and to gather reviews for your Application or
          Service; or
          <br />
          if you are a business owner, your internal business purposes including
          finding Applications to use for your business, reading reviews from
          other users and finding and connecting with Service Providers who
          provide Services to assist with Application set up; and
          <br />
          lawful purposes (including complying with the Unsolicited Electronic
          Messaging Act 2007); and
          <br />
          not resell or make available The Right Tool to any third party, or
          otherwise commercially exploit The Right Tool.
        </p>

        <p>
          You must provide true, current and complete information in your
          dealings with us (including when setting up an account), and must
          promptly update that information as required so that the information
          remains true, current and complete.
        </p>

        <p>
          You must keep your User ID secure and:
          <br />
          not permit any other person to use your User ID, including not
          disclosing or providing it to any other person; and <br />
          immediately notify us if you become aware of any unauthorised use or
          disclosure of your User ID, by sending an email to
          find@therighttool.co.nz.
        </p>

        <p>
          You must obtain our written permission to establish a link to The
          Right Tool. If you wish to do so, email your request to
          find@therighttool.co.nz.
        </p>

        <p>
          When accessing and using The Right Tool, you must:
          <br />
          not impersonate another person or misrepresent authorisation to act on
          behalf of others or us;
          <br />
          not attempt to undermine the security or integrity of the Underlying
          Systems; <br />
          not use, or misuse, The Right Tool in any way which may impair the
          functionality of the Underlying Systems or impair the ability of any
          other user to use The Right Tool;
          <br />
          not attempt to view, access or copy any material or data other than:
          <br />
          that which you are authorised to access; and
          <br />
          to the extent necessary for you to use The Right Tool in accordance
          with these Terms; <br />
          neither use The Right Tool, nor transmit, input or store any Content,
          that breaches any third party right (including Intellectual Property
          Rights and privacy rights) or is Objectionable, incorrect or
          misleading; and
          <br />
          unless with our agreement, access The Right Tool via standard web
          browsers and not by any other method. Other methods include scraping,
          deep-linking, harvesting, data mining, use of a robot or spider,
          automation, or any similar data gathering, extraction, monitoring or
          input method.
        </p>

        <p>
          You are responsible for procuring all licences, authorisations and
          consents required for you to access and use The Right Tool, including
          to use, store and input Content into, and display Content using, The
          Right Tool.
        </p>

        <p>
          You indemnify us against all Loss we suffer or incur as a direct or
          indirect result of:
          <br />
          any actual or alleged claim by a third party that any Content
          infringes the rights of that third party (including Intellectual
          Property Rights and privacy rights) or that the Content is
          Objectionable, incorrect or misleading; <br />
          your failure to comply with these Terms, including any failure of a
          person who accesses and uses The Right Tool by using your User ID; or
          <br />
          any Loss arising from or in connection with an actual or alleged
          breach by you of any legal or regulatory requirements which occurs in
          connection with a Listing, Application or Service, or as a result of
          any other relationship established through The Right Tool.
        </p>

        <p></p>

        <p class="termTitle">ADDITIONAL TERMS FOR ADVERTISERS</p>

        <p>This clause 6 applies to Advertisers.</p>

        <p>On creating a Listing, you must:</p>

        <p>
          provide accurate information on the Application or Service offered;
          <br />
          disclose the price payable for the Application or Service (including
          any price payable on the expiry of any free trial period); and <br />
          provide any additional terms and conditions that apply to the
          Application or Service offered.
        </p>

        <p>
          All descriptions and information in a Listing must be accurate,
          complete, up-to-date and truthful to the best of your knowledge and
          belief.
        </p>

        <p>
          If an Application or Service becomes unavailable for any reason, you
          agree to remove or suspend the Listing as soon as practicable.
        </p>

        <p>
          You acknowledge and agree that you are responsible for all Listings
          that you post on The Right Tool. Accordingly, you represent and
          warrant that any Listing you post, or a The Right Tool user’s use of
          an Application or Service will:
          <br />
          comply any agreements you have entered into with any third parties;{" "}
          <br />
          comply with all applicable laws; and
          <br />
          not conflict with the rights of third parties.
        </p>

        <p></p>

        <p class="termTitle">ADDITIONAL TERMS FOR THE RIGHT TOOL USERS</p>

        <p>
          This clause 7 applies to The Right Tool users where the user is not
          using The Right Tool as an Advertiser.
        </p>

        <p>
          The Right Tool may allow you to submit or post material such as
          comments, ratings and reviews (Features). The purpose of these
          Features is to provide a system for users to rate and comment on the
          Application or Service they have accessed, purchased or engaged with
          that gives unbiased advice and feedback on what has worked for their
          business.
        </p>

        <p>
          If you use the Features, you agree that we may post all or a portion
          of the content you submit, along with your name and the date that you
          submitted that content. We reserve the right to edit, remove or
          decline to post any content submitted through the Features, at any
          time, in our discretion. We are not responsible for the content
          submitted through the Features, whether or not we reviewed or edited
          that content.
        </p>

        <p>
          Your use of such Features must comply with the following submission
          guidelines (which may be updated from time to time). You may not use
          the Features to:
          <br />
          post any materials that (i) you do not have permission, right or
          license to use, or (ii) infringe on the rights of any third party;
          <br />
          post Objectionable content;
          <br />
          post personal, private or confidential information belonging to
          others;
          <br />
          request personal information from a minor;
          <br />
          impersonate or misrepresent your affiliation with another person, or
          entity;
          <br />
          post or transmit spam, including but not limited to unsolicited or
          unauthorized advertising, promotional materials, or informational
          announcements;
          <br />
          post, modify, or remove a rating or review in exchange for any kind of
          compensation or incentive; <br />
          post a fake rating or review; and
          <br />
          plan or engage in any illegal, fraudulent, or manipulative activity.
        </p>

        <p>
          Without limiting clause 9.1 or 9.2, you grant us a world-wide, royalty
          free, perpetual, non-exclusive licence to use the material you submit
          when using the Features within The Right Tool, for related marketing
          and our business purposes (and this right will survive termination or
          expiry of these Terms).
        </p>

        <p></p>

        <p class="termTitle">FEES AND PAYMENT</p>

        <p>This clause 8 applies to Service Providers.</p>

        <p>You must pay us the Fees.</p>

        <p>
          The Fees will be charged to your valid debit/credit card in advance of
          each Subscription Period.
        </p>

        <p>
          Unless you cancel your right to use The Right Tool prior to the end of
          your current Subscription Period in accordance with clause 15, at the
          end of each Subscription Period we will automatically roll over your
          subscription for a further Subscription Period of the same length. We
          will charge your debit/credit card the Fees in advance of each
          Subscription Period.
        </p>

        <p>
          If we are unable to collect the Fees from your debit/credit card for
          any reason, including where your card has expired or there are
          insufficient funds, you remain responsible for any uncollected amounts
          and we may suspend or cancel your access to The Right Tool without
          giving you notice.
        </p>

        <p>
          We may, by giving at least 30 days’ notice, increase the Fees from
          time to time. We will notify you of any increase and the effective
          date of the increase by emailing you at the email contact address that
          you have most recently supplied to us. If you do not wish to pay the
          increased Fees, you may remove your Listing and/or your account. If
          you do not remove your Listing before the effective date of the
          increase, you are deemed to have accepted the increased Fees.
        </p>

        <p class="termTitle">CONTENT</p>

        <p>
          Title to, and all Intellectual Property Rights in, the Content (as
          between the parties) remains your property. You grant us a worldwide,
          non-exclusive, fully paid up, transferable, irrevocable licence to
          use, store, copy, modify, make available and communicate the Content
          for any purpose in connection with the exercise of our rights and
          performance of our obligations in accordance with these Terms.
        </p>

        <p>
          Without limiting clause 9.1, you acknowledge that we may use Content
          for our internal research, analytical and product development
          purposes, to conduct statistical analysis and identify trends and
          insights (on an anonymised and aggregated basis) and for our internal
          reporting requirements (and these rights will survive termination and
          expiration of these Terms).
        </p>

        <p>
          You must ensure you have obtained all necessary consents for us to
          access, collect, hold, process and distribute the Content as described
          in these Terms.
        </p>

        <p>
          While we will use reasonable endeavours to back up all Content stored
          using The Right Tool, you must keep separate and regular back-up
          copies of all Content uploaded by you onto The Right Tool.
        </p>

        <p></p>

        <p class="termTitle">OUR INTELLECTUAL PROPERTY</p>

        <p>
          Other than your Content, we (and our licensors) own all proprietary
          and intellectual property rights in The Right Tool and the Underlying
          Systems, including all information, data, text, graphics, artwork,
          photographs, trade marks, logos, icons, sound recordings, videos and
          look and feel, and including any modification, enhancement or
          derivative work of any of the foregoing.
        </p>

        <p>
          Advertisers own all intellectual property rights in the Applications
          and Services.
        </p>

        <p>
          If you provide us with ideas, comments or suggestions relating to The
          Right Tool or Underlying Systems (together feedback):
          <br />
          all Intellectual Property Rights in that feedback, and anything
          created as a result of that feedback (including new material,
          enhancements, modifications or derivative works), are owned solely by
          us; and
          <br />
          we may use or disclose the feedback for any purpose.
        </p>

        <p></p>

        <p class="termTitle">CONFIDENTIALITY</p>

        <p>
          You must treat all information available and otherwise provided
          through The Right Tool as strictly confidential, and may only use that
          information for the purpose of purchasing or using Applications or
          Services or providing those Applications or Services through The Right
          Tool.
        </p>

        <p>
          Confidential Information expressly includes contact details of each
          The Right Tool user, but does not include any information already in
          the public domain, or independently known to you.
        </p>

        <p></p>

        <p class="termTitle">PRIVACY</p>

        <p>
          You are not required to provide personal information to us, although
          in some cases if you choose to not do so then we will be unable to
          make certain functions of The Right Tool unavailable to you.
        </p>

        <p>
          When you provide personal information to us, we will comply with the
          New Zealand Privacy Act 2020 and with our Privacy Policy set out at
          [insert].
        </p>

        <p></p>

        <p class="termTitle">DISCLAIMERS</p>

        <p>
          To the extent permitted by law, we have no liability or responsibility
          to you or any other person for any Loss in connection with:
          <br />
          The Right Tool being unavailable (in whole or in part) or performing
          slowly;
          <br />
          the failure of any The Right Tool user to comply with these Terms;{" "}
          <br />
          any error in, or omission from, any information made available through
          The Right Tool;
          <br />
          any exposure to viruses or other forms of interference which may
          damage your computer system or expose you to fraud when you access or
          use The Right Tool, an Application or a Service. To avoid doubt, you
          are responsible for ensuring the process by which you access and use
          The Right Tool protects you from this; and
          <br />
          any site linked from The Right Tool. Any link on The Right Tool to
          other sites does not imply any endorsement, approval or recommendation
          of, or responsibility for, those sites, their Applications, Services,
          contents, operations, products or operators.
        </p>

        <p>
          All advertisements, arrangements and agreements to purchase, use or
          provide an Application or Service through The Right Tool are carried
          out entirely at your own risk. We exclude to the fullest extent
          permitted by applicable law all liability for any Loss arising out of
          or in any way connected with any other arrangements that you may have
          made in connection with The Right Tool, or your use of The Right Tool.
        </p>

        <p>
          Without limiting clause 13.2:
          <br />
          you rely on information and all reviews and ratings provided by other
          The Right Tool users at your own risk;
          <br />
          you acknowledge we do not, control, inspect, endorse, approve or check
          the availability, condition or nature of any Application or Service,
          or the accuracy, currency, truth or completeness of the information
          provided by The Right Tool users and it is your responsibility to do
          so; and
          <br />
          it is your responsibility to take any other necessary precautions
          before entering an agreement to purchase or use an Application or
          Service.
        </p>

        <p></p>

        <p class="termTitle">LIABILITY</p>

        <p>To the maximum extent permitted by law:</p>

        <p>
          you access and use The Right Tool at your own risk; and
          <br />
          we are not liable or responsible to you or any other person for any
          Loss under these Terms or in connection with The Right Tool, or your
          access and use of (or inability to access or use) The Right Tool. This
          exclusion applies regardless of whether our liability or
          responsibility arises in contract, tort (including negligence),
          equity, breach of statutory duty, or otherwise.
        </p>

        <p>
          Except to the extent permitted by law, nothing in the Agreement has
          the effect of contracting out of the New Zealand Consumer Guarantees
          Act 1993 or any other consumer protection law that cannot be excluded.
          To the extent our liability cannot be excluded but can be limited, our
          liability will be limited to $100.
        </p>

        <p>
          To the maximum extent permitted by law and only to the extent clauses
          14.1 and 14.2 of these Terms do not apply: <br />
          our total liability to you in connection with these Terms or The Right
          Tool will not exceed NZD100; and <br />
          we will not be liable to you under or in connection with these Terms
          or The Right Tool for any:
          <br />
          loss of profit, revenue, savings, business, use, data (including
          Content), and/or goodwill; or
          <br />
          consequential, indirect, incidental or special damage or loss of any
          kind.
        </p>

        <p>
          Clauses 14.1 to 14.3 do not apply to limit:
          <br />
          our liability under or in connection with these Terms:
          <br />
          for personal injury or death; or
          <br />
          for fraud or wilful misconduct; or
          <br />
          any liability that cannot be excluded by law.
        </p>

        <p></p>

        <p class="termTitle">SUSPENSION AND TERMINATION</p>

        <p>
          You may cease using The Right Tool at any time by removing your
          account from The Right Tool. If you do this, these Terms and your
          right to access and use The Right Tool will terminate immediately.
        </p>

        <p>
          Either party may, by notice to the other party, immediately terminate
          these Terms and your right to access and use The Right Tool if the
          other party:
          <br />
          breaches any material provision of the Agreement and the breach is
          not:
          <br />
          remedied within 10 days or the receipt of a notice from the first
          party requiring it to remedy the breach; or
          <br />
          capable of being remedied; or
          <br />
          becomes insolvent, liquidated, bankrupt, has an administrator,
          receiver, liquidator, statutory manager, mortgagee’s or chargee’s
          agent appointed or becomes subject to any form of insolvency action of
          external administration, or ceases to continue business for any
          reason.
        </p>

        <p>
          Termination of these Terms does not affect either party’s rights and
          obligations that accrued before that termination.
        </p>

        <p>
          Clauses which, by their nature, are intended to survive termination of
          your right to access and use The Right Tool, including clauses 9 to
          14, 15.3, 15.4 and 16.2.
        </p>

        <p>
          No compensation is payable by us to you as a result of termination of
          these Terms for whatever reason, and you will not be entitled to a
          refund of any amount that you have already paid to us.
        </p>

        <p>
          Without limiting any other right or remedy available, we may restrict
          or suspend your access to The Right Tool if we consider you have:{" "}
          <br />
          undermined, or attempted to undermine, the security or integrity of
          The Right Tool or any Underlying Systems;
          <br />
          used, or attempted to use, The Right Tool for improper purposes or in
          a manner, other than for normal operational purposes, that materially
          reduces the operational performance of The Right Tool; <br />
          transmitted or stored any Content, review or rating that breaches or
          may breach these Terms or any third party right (including
          Intellectual Property Rights and privacy rights), or that is, or may
          be, Objectionable, incorrect or misleading; <br />
          failed to pay any amount when due in accordance with clause 8; or{" "}
          <br />
          otherwise materially breached these Terms.
        </p>

        <p></p>
        <p class="termTitle">GENERAL</p>

        <p>
          If we need to contact you, we may do so by email or by posting a
          notice on The Right Tool. You agree that this satisfies all legal
          requirements in relation to written communications.
        </p>

        <p>
          These Terms, and any dispute relating to these Terms or The Right
          Tool, are governed by and must be interpreted in accordance with the
          laws of New Zealand. Each party submits to the exclusive jurisdiction
          of the Courts of New Zealand in relation to any dispute connected with
          these Terms or The Right Tool.
        </p>

        <p>
          We are not liable to you for any failure to perform our obligations
          under these Terms to the extent caused by an event that is beyond our
          reasonable control or was not reasonably foreseeable.
        </p>

        <p>
          You may not assign, novate, subcontract or transfer any right or
          obligation under these Terms without our prior written consent, that
          consent not to be unreasonably withheld. You remain liable for your
          obligations under these Terms despite any approved assignment,
          subcontracting or transfer.
        </p>

        <p>
          No person other than us and you has any right to a benefit under, or
          to enforce, these Terms.
        </p>

        <p>
          Subject to clause 2.1, any variation to these Terms must be in writing
          and signed by both parties.
        </p>

        <p>
          For us to waive a right under these Terms, that waiver must be in
          writing and signed by us.
        </p>

        <p>
          If any part or provision of these Terms is or becomes illegal,
          unenforceable, or invalid, that part or provision is deemed to be
          modified to the extent required to remedy the illegality,
          unenforceability or invalidity. If a modification is not possible, the
          part or provision must be treated for all purposes as severed from
          these Terms. The remainder of these Terms will be binding on you.
        </p>

        <p>
          These Terms set out everything relating to your access and use of The
          Right Tool and supersede and cancel anything discussed, exchanged or
          agreed prior to you agreeing to these Terms. The parties have not
          relied on any representation, warranty or agreement relating to The
          Right Tool that is not expressly set out in the Terms, and no such
          representation, warranty or agreement has any effect from the date you
          agree to these Terms.
          <br />
        </p>
      </div>
    </div>
  );
}

class terms extends Component {
  render() {
    return (
      <div id="results_page">
        <Helmet>
          <title>Platform terms of use - The Right Tool</title>
          <meta
            name="description"
            content="These Terms apply to your use of The Right Tool. Find out more about them here."
          />
        </Helmet>
        <FilterMainOld
          search={false}
          text={"Platform Terms of Use"}
          top="The Right Tool"
        />
        <Terms />
        <SmallCTA
          sub={"Looking for best of breed software for your business?"}
          button_text={"Check out the app directory"}
          button_link={"/directory/apps/accounting?n=Accounting"}
        />
      </div>
    );
  }
}

export default terms;
