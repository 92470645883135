import React, { Component } from "react";
import Menu from "../components/menu/menu";
import Footer from "../components/footer/footer";
import Newfiltermain from "../components/newfilter_main/newfiltermain";
import Toggle from "../components/toggle/toggle";

class V2Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directory: "",
      message: "",
      urlstate: "",
    };
  }

  callbackFunction = (childData, url) => {
    this.setState({
      message: childData,
      urlstate: url,
    });
  };

  componentDidMount() {
    if (this.state.childData === undefined) {
      this.setState({
        message: window.location.pathname.split("/").slice(-1).join("/"),
      });
    }
  }
  render() {
    return (
      <div class="min-height opacityfade">
        <Menu transparent={false} />
        <a class="skiplink" href="#maincontentskip">
          Skip To Content
        </a>
        <div id="results_page" name={this.state.message}>
          <Newfiltermain
            parentParentCallback={this.callbackFunction}
            homepagepass={this.state.message}
            search={true}
            text={"Here are the right tools for"}
          />
          {this.props.children}
        </div>
        <Footer />
      </div>
    );
  }
}
export default V2Results;
