import React, { Component } from 'react'
import Filter from '../components/filter/filter'
import Listing from '../components/listing/listing'
import Testimonial from '../components/testimonial/testimonial'
import CTA from '../components/cta/cta'
import Cookie from 'js-cookie'
import ServiceCta from '../components/service_cta/service_cta'
import image_cta from '../assets/images/image_cta.png'
import image_cta2 from '../assets/images/imagecta2.png'
import Button from '../components/button/button'
import { Helmet } from 'react-helmet'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

function ToolCTA (props) {
  let redirect = window.location.pathname + window.location.search
  redirect = redirect.replace('apps', 'cloud-experts')
  return (
    <div
      id="imageCTAcontainer"
      class={`d-2-12 background_blue toolCTA gc no-gap`}
    >
      <div class="imageCTA_inner ">
        <h2 class="white">{props.text}</h2>

        <div
          class="pointer"
          onClick={
            props.link === 'toggleTab'
              ? () => (window.location = `${redirect}`)
              : null
          }
        >
          <Button
            text={props.buttontxt}
            color={props.color}
            link={props.link === 'toggleTab' ? null : props.link}
          />
        </div>
      </div>

      <div
        class="imageCTA_inner imageCTA"
        style={{
          backgroundImage: `url( ${props.image})`,
        }}
      ></div>
    </div>
  )
}

class apps extends Component {
  constructor (props) {
    super(props)
    this.state = {
      industries: '',
      need: [],
      loaded: false,
      loading: true,
      manage: [],
      grow: [],
      industriesName: '',
      app_likes: [],
      url: '',
      filter: 'All Categories',
      manageFilter: 'All Categories',
      needFilter: 'All Categories',
      growFilter: 'All Categories',
      needCategories: [],
      manageCategories: [],
      growCategories: [],
    }
    var setFilter = this.setFilter.bind(this)
  }

  setFilter (someArg) {
    this.setState({ filter: someArg })
  }

  toggleTab () {}

  fetcher () {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ app_likes: this.state.data })
        for (let i = 0; i < this.state.app_likes.length; i++) {
          const element = this.state.app_likes[i]
        }
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  addFunction = (e) => {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: e.url,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let dataarr = data
        let array = {
          listing: {
            url: data.listing,
          },
          url: data.url,
        }
        this.setState({ wishlistAdded: true })
        // dataarr.splice(2,1, array)
        var joined = this.state.app_likes.concat(array)

        this.setState({ app_likes: joined })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  removeFunction = (i) => {
    let urldelete = i.url
    for (let i = 0; i < this.state.app_likes.length; i++) {
      if (this.state.app_likes[i].listing.url === urldelete) {
        let url = this.state.app_likes[i].url
        var array = [...this.state.app_likes] // make a separate copy of the array

        var index = array.indexOf(i)

        array.splice(i, 1)
        this.setState({ app_likes: array })
        fetch(
          process.env.REACT_APP_API_URL + `/likes/apps/${url
            .split('/')
            .slice(-2)
            .join('/')}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${token}`,
            },
          },
        )
          .then((data) => {
            this.setState({ wishlistAdded: false })
          })
          .catch((error) => {
            this.setState({ error: error })
            console.log(this.state.error)
          })
      }
    }
  }

  setNeedFilter (someArg) {
    this.setState({ needFilter: someArg })
  }

  setManageFilter (someArg) {
    this.setState({ manageFilter: someArg })
  }

  setGrowFilter (someArg) {
    this.setState({ growFilter: someArg })
  }

  componentWillMount () {
    if (this.props.match.params.handle) {
      this.setState(
        { industries: this.props.match.params.handle },
        function () {
          if (token) {
            fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${token}`,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                this.setState({ app_likes: data })
                this.fetchIndustriesListings()
              })
              .catch((error) => {
                this.setState({ error: error })
                console.log(this.state.error)
              })
          } else {
            this.fetchIndustriesListings()
          }
        },
      )
    }
  }

  fetchIndustriesListings = (e) => {
    fetch(
      process.env.REACT_APP_API_URL + `/industries/${this.state.industries}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ loading: false, industriesName: data.name })
        for (let o = 0; o < data.categories.length; o++) {
          if (
            data.categories[o].name === 'Need' &&
            data.categories[o].apps.length > 0
          ) {
            let newarray = []
            for (let i = 0; i < data.categories[o].apps.length; i++) {
              for (
                let j = 0;
                j < data.categories[o].apps[i].subcategories.length;
                j++
              ) {
                let subname = data.categories[o].apps[i].subcategories[j].name
                if (!newarray.includes(subname)) {
                  newarray.push(subname)
                  this.setState({
                    need: data.categories[o].apps,
                    needCategories: newarray,
                  })
                }
              }
            }
            // this.setState({ need: data.categories[0].apps });
          } else if (
            data.categories[o].name === 'Manage' &&
            data.categories[o].apps.length > 0
          ) {
            let newarray = []
            for (let i = 0; i < data.categories[o].apps.length; i++) {
              for (
                let j = 0;
                j < data.categories[o].apps[i].subcategories.length;
                j++
              ) {
                let subname = data.categories[o].apps[i].subcategories[j].name
                if (!newarray.includes(subname)) {
                  newarray.push(subname)
                  this.setState({
                    manage: data.categories[o].apps,
                    manageCategories: newarray,
                  })
                }
              }
            }
            // this.setState({ need: data.categories[0].apps });
          } else if (
            data.categories[o].name === 'Grow' &&
            data.categories[o].apps.length > 0
          ) {
            let newarray = []
            for (let i = 0; i < data.categories[o].apps.length; i++) {
              for (
                let j = 0;
                j < data.categories[o].apps[i].subcategories.length;
                j++
              ) {
                let subname = data.categories[o].apps[i].subcategories[j].name
                if (!newarray.includes(subname)) {
                  newarray.push(subname)

                  this.setState({
                    grow: data.categories[o].apps,
                    growCategories: newarray,
                  })
                  console.log(this.state.growCategories)
                }
              }
            }
            // this.setState({ need: data.categories[0].apps });
          }
        }
        this.setState({ loaded: true })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  render () {
    const filter = this.state.filter
    const manageFilter = this.state.manageFilter
    const growFilter = this.state.growFilter
    const needFilter = this.state.needFilter
    var setFilter = this.setFilter
    var setManageFilter = this.setManageFilter
    var setGrowFilter = this.setGrowFilter
    var setNeedFilter = this.setNeedFilter
    var addFunction = this.addFunction
    var removeFunction = this.removeFunction
    const { grow, manage, need, loading, app_likes, industriesName } =
      this.state
    if (loading) {
      return (
        <div class="gc no-gap splashscreen">
          <div class="d-2-12 t-3-11 m-1-13 ">
            <svg class="splash-spinner" viewBox="0 0 50 50">
              <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
              ></circle>
            </svg>
          </div>
        </div>
      )
    }

    if (need.length === 0 && manage.length === 0 && grow.length === 0) {
      return (
        <div class="opacityfade">
          <div class="content center_all text_center">
            <h4>Empty, try another industry.</h4>
          </div>
          <ServiceCta
            text={'Need help getting set up with these apps?'}
            sub={
              'Find cloud experts that can get you started on any of these apps. So you can get the help when you need it.'
            }
            button_text={'More Cloud Experts'}
            button_link={''}
            industries={`${this.state.industries}`}
          />
          <Testimonial
            text={
              'Take a peek at these other toolkits from other great Kiwi businesses.'
            }
            industry={`${this.state.industries}`}
            cta={false}
          />
          <CTA
            text={'Want your app in the directory?'}
            sub={'Get your app to show for kiwi businesses today.'}
            button_text={'List My App'}
            button_link={''}
          />
        </div>
      )
    } else {
      return (
        <div id="maincontentskip" class="opacityfade" key={this.props.pageId}>
          <Helmet>
            <title>
              {' '}
              Find the best {this.state.industries} apps for your business - The
              Right Tool
            </title>
            <meta
              property="og:image"
              content={`https://therighttool.co.nz/TheRightTool_Meta_${this.state.industries}.png`}
            />
            <meta
              name="description"
              content={`Real reviews for ${this.state.industries} apps to help you find the tools to help you manage your ${this.state.industries} business. Aotearoa’s leading ${this.state.industries} app directory. Quickly find the best ${this.state.industries} apps.`}
            />
          </Helmet>
          <div
            class={
              this.state.need.length > 0
                ? 'gc no-gap center_all listing_container'
                : 'empty '
            }
          >
            <div class="d-2-12 t-2-12 m-2-12 ">
              <div class="split_between">
                <h2>Tools you need</h2>
                <Filter
                  id="1filterul"
                  idlist="1filterlist"
                  data={this.state.needCategories}
                  setFilter={setNeedFilter.bind(this)}
                />
              </div>
            </div>
            <div class="d-2-12 t-1-13 m-1-13 gc profile">
              {this.state.need.map(function (listing, i) {
                let URLvar = listing.url.replace(/\/$/, '')
                URLvar = URLvar.replace(/^(.*[\\\/])/g, '')
                let wishlistAdded = false

                for (let i = 0; i < app_likes.length; i++) {
                  if (app_likes[i].listing.url === listing.url) {
                    wishlistAdded = true
                  }
                }

                for (let j = 0; j < listing.subcategories.length; j++) {
                  if (listing.subcategories[j].name === needFilter) {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/app/${URLvar}`}
                      />
                    )
                  } else if (needFilter === 'All Categories') {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/app/${URLvar}`}
                      />
                    )
                  } else {
                    return null
                  }
                }
              })}
            </div>
            <ToolCTA
              image={image_cta}
              text="Find an expert who can help you set these apps up."
              buttontxt="See Cloud Experts"
              link={'toggleTab'}
              color="white"
            />
          </div>
          <div
            class={
              this.state.manage.length > 0
                ? 'gc no-gap center_all listing_container'
                : 'empty '
            }
          >
            <div class="d-2-12 t-2-12 m-2-12 ">
              <div class="split_between">
                <h2>Tools to manage</h2>
                <Filter
                  id="3filterul"
                  idlist="3filterlist"
                  data={this.state.manageCategories}
                  setFilter={setManageFilter.bind(this)}
                />
              </div>
            </div>
            <div class="d-2-12 t-1-13 m-1-13 gc profile">
              {this.state.manage.map(function (listing, i) {
                let URLvar = listing.url.replace(/\/$/, '')
                URLvar = URLvar.replace(/^(.*[\\\/])/g, '')
                let wishlistAdded = false
                for (let i = 0; i < app_likes.length; i++) {
                  if (app_likes[i].listing.url === listing.url) {
                    wishlistAdded = true
                  }
                }

                for (let j = 0; j < listing.subcategories.length; j++) {
                  if (listing.subcategories[j].name === manageFilter) {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/app/${URLvar}`}
                      />
                    )
                  } else if (manageFilter === 'All Categories') {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/app/${URLvar}`}
                      />
                    )
                  } else {
                    return null
                  }
                }
              })}
            </div>
            <ToolCTA
              image={image_cta2}
              text="Create a free account to save these apps to your toolbox and leave reviews."
              buttontxt="Create Account"
              link="https://admin.therighttool.co.nz/auth/registration"
              color="dblue"
            />
          </div>

          <div
            class={
              this.state.grow.length > 0
                ? 'gc no-gap center_all listing_container'
                : 'empty '
            }
          >
            <div class="d-2-12 t-2-12 m-2-12 ">
              <div class="split_between">
                <h2>Tools to grow</h2>
                <Filter
                  id="2filterul"
                  idlist="2filterlist"
                  data={this.state.growCategories}
                  setFilter={setGrowFilter.bind(this)}
                />
              </div>
            </div>
            <div class="d-2-12 t-1-13 m-1-13 gc profile">
              {this.state.grow.map(function (listing, i) {
                let URLvar = listing.url.replace(/\/$/, '')
                URLvar = URLvar.replace(/^(.*[\\\/])/g, '')
                let wishlistAdded = false
                for (let i = 0; i < app_likes.length; i++) {
                  if (app_likes[i].listing.url === listing.url) {
                    wishlistAdded = true
                  }
                }
                for (let j = 0; j < listing.subcategories.length; j++) {
                  if (listing.subcategories[j].name === growFilter) {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/app/${URLvar}`}
                      />
                    )
                  } else if (growFilter === 'All Categories') {
                    return (
                      <Listing
                        addFunction={
                          wishlistAdded
                            ? removeFunction.bind(this)
                            : addFunction.bind(this)
                        }
                        wishlist={wishlistAdded}
                        data={listing}
                        link={`/profile/app/${URLvar}`}
                      />
                    )
                  } else {
                    return null
                  }
                }
              })}
            </div>
          </div>

          <ServiceCta
            text={'Need help getting set up with these apps?'}
            sub={
              'Find cloud experts that can get you started on any of these apps. So you can get the help when you need it.'
            }
            button_text={'More Cloud Experts'}
            button_link={''}
            industries={`${this.state.industries}`}
          />
          <Testimonial
            text={
              'Take a peek at these other toolkits from other great Kiwi businesses.'
            }
            industry={`${this.state.industries}`}
            cta={false}
          />
          <CTA
            text={'Want your app in the directory?'}
            sub={'Get your app to show for kiwi businesses today.'}
            button_text={'List My App'}
            button_link={'/list-an-app'}
          />
        </div>
      )
    }
  }
}

export default apps
