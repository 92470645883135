import React, { Component } from "react";
import ReactStars from "react-rating-stars-component";
import "./reviews.scss";
class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const comment = this.props.data.comment;
    const stars = this.props.data.rating;
    const button_text = this.props.button_text;
    const button_link = this.props.button_link;

    return (
      <div class="reviews">
        <div class="review_contaner">

        <ReactStars
          value={stars ? stars : 0}
          size="30px"
          starSpacing="1px"
          activeColor={"#FAAA00"}
        />
        <div>
          <span class="qoute" itemprop="review" >"{comment}"</span>
        </div>
        </div>
   
      </div>
    );
  }
}

export default Reviews;
