import React, { Component } from "react";
import "./team.scss";
import noImage from "../../assets/images/noImage.png";
class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const name = this.props.data.name;
    const position = this.props.data.position;
    const photo = this.props.data.photo;
    const description = this.props.data.description;

    return (
      <div class="team_member">
        <img src={photo === null ? noImage : photo} alt="team member" />
        <h5>{name}</h5>
        <span>{position}</span>
        <p>{description}</p>
      </div>
    );
  }
}

export default Team;
