import React, { Component } from "react";
import SmallCTA from "../components/small_cta/small_cta";
import FilterMainOld from "../components/filter_main/filtermain_old";
import { Helmet } from "react-helmet";
function OurStory(props) {
  return (
    <div id="OurStory" class="gc  center_all content">
      <div class="d-2-12 t-3-11 m-2-12 ">
        <div
          class="typeform-widget"
          data-url="https://form.typeform.com/to/aUQydUOu?typeform-medium=embed-snippet"
          style={{ width: "100%", height: "650px" }}
        ></div>
      </div>
    </div>
  );
}

class casestudy1 extends Component {
  render() {
    return (
      <div id="site_page">
        <Helmet>
          <meta
            name="description"
            content=" Quickly find out what tools you should use for your business. Try the Free Tool Finder free. "
          />
          <meta
            property="og:image"
            content="https://therighttool.co.nz/TheRightTool.jpg"
          />
          <title>
            Free Tool Finder: Let us suggest The Right Tool for your business
          </title>
        </Helmet>
        <FilterMainOld search={false} text={"Free Tool Finder"} />
        <OurStory />
        <SmallCTA
          sub={"Looking for best of breed software for your business?"}
          button_text={"Check out the app directory"}
          button_link={"/directory/apps/accounting?n=Accounting"}
        />
      </div>
    );
  }
}

export default casestudy1;
