import Cookie from 'js-cookie'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

export async function industries () {
  const data = await fetch(process.env.REACT_APP_API_URL + '/industries/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return {
    data,
  }
}

export async function categories () {
  const data = await fetch(process.env.REACT_APP_API_URL + '/v2/categories/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return {
    data,
  }
}

export async function addLike (params) {
  const data = await fetch(process.env.REACT_APP_API_URL + '/likes/apps/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      listing: params.listing,
    }),
  })
  return {
    data,
  }
}

export async function removeLike (params) {
  const data = await fetch(
    process.env.REACT_APP_API_URL + `/likes/apps/${params.listing}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify(params),
    },
  )
  return {
    data,
  }
}

export async function getSubcategory (params) {
  const name = params.name.replace(/ /g, '-')
  const str = name.replace(/[()]/g, '')
  const data = await fetch(
    process.env.REACT_APP_API_URL + `/v2/subcategories/${str}${
      params.industry ? '/?industry=' + params.industry : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return {
    data,
  }
}

export async function getServices () {
  const data = await fetch(process.env.REACT_APP_API_URL + '/services/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return {
    data,
  }
}

export async function getLikes () {
  const data = await fetch(process.env.REACT_APP_API_URL + '/likes/apps/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${token}`,
    },
  })
  return {
    data,
  }
}

export async function getIndustry (params) {
  const name = params.name.replace(/ /g, '-')
  const data = await fetch(
    process.env.REACT_APP_API_URL + `/industries/${name}/`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return {
    data,
  }
}

export async function categoryAPI (params) {
  const data = await fetch(
    process.env.REACT_APP_API_URL + `/v2/categories/${params.name}${
      params.industry ? '?industry=' + params.industry : ''
    }${params.limit && !params.industry ? '?n=' + params.limit : ''}${
      params.limit && params.industry ? '&n=' + params.limit : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
  return {
    data,
  }
}

export async function randomproviders () {
  const data = await fetch(
    process.env.REACT_APP_API_URL + '/services/?random=4', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  return {
    data,
  }
}
