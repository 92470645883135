import React, { Component, useState, useCallback } from "react";
import "./casestudylisting.scss";
import Button from "../button/button";

const Casestudylisting = (props) => {
  function handleClick() {
    window.location.href = props.button_link;
  }
  return (
    <div class="listing casestudylisting" onClick={() => handleClick()}>
      <img
        src={props.img}
        style={{ objectFit: "cover" }}
        alt="The Listing logo"
      ></img>
      <div class="listingDetail">
        <div class="listingWrapper">
          <div>
            <div class={props.logo ? "logocase" : "hide"}>
              <img
                src={props.logo && props.logo}
                class={!props.logo && "hide"}
              />
            </div>

            <h4>{props.title && props.title}</h4>
            <p class="listing_p">{props.qoute}</p>
            <div class="btn_container">
              <a href={props.button_link}>
                <span class="blue">{props.button_text}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Casestudylisting;
