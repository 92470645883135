import React, { Component, useState, useCallback } from "react";
import "./search.scss";
import Button from "../button/button";

const formSubmit = (e, query) => {
  e.preventDefault();
  window.location.href = `/search/results?search=${query}`;
};
const Search = props => {
  // const isvisible = this.props.visble
  const [query, setQuery] = useState(
    new URLSearchParams(window.location.search).get("search")
  );
  return (
    <div className="gc no-gap searchWrapper">
      <div class="searchContainer d-2-12 t-2-12 m-2-12 ">
        <form id="search" onSubmit={e => formSubmit(e, query)}>
          <input
            id="searchInputField"
            value={query}
            onChange={e => setQuery(e.target.value)}
          ></input>
        </form>

        <Button
          text={`Search`}
          color="blue"
          link={`/search/results?search=${query}`}
        />
      </div>
      <div class="d-2-12 t-2-12 m-2-12 ">
        <span class="subsearchtext">
          Search for products, service providers, industries & locations
        </span>
      </div>
    </div>
  );
};

export default Search;
