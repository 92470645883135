import React, { Component } from 'react'
import FilterMainOld from '../components/filter_main/filtermain_old'
import SmallCTA from '../components/small_cta/small_cta'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from '../components/button/button'
import Cookie from 'js-cookie'
import { Helmet } from 'react-helmet'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

class contact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      contact: '',
      errors: [],
      loading: false,
      sent: false,
      recaptcha: false,
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.submit = this.handleSubmit.bind(this)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ loading: true })
    if (!this.state.recaptcha) {
      this.setState({ loading: false })
    } else {
      let datanew = new FormData()
      datanew.append('first_name', this.state.name)
      datanew.append('message', this.state.message)
      datanew.append('number', this.state.contact)
      datanew.append('email', this.state.email)

      fetch(
        process.env.REACT_APP_API_URL +
        '/contact/408a54f3-3a16-48f1-814b-ac19b00ba0a1/',
        {
          method: 'POST',
          headers: {
            Authorization: `${token}`,
          },
          body: datanew,
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong ...')
          }
        })
        .then((data) => {
          this.setState({ loading: false, sent: true })
        })
        .catch((error) => {
          this.setState({ saving: false })
          this.setState({ error: error })
          console.log(error)
        })
    }
  }

  handleFormChange (evt) {
    const value = evt.target.value
    this.setState({
      ...this.state,
      [evt.target.name]: value,
    })
  }

  onChange (value) {
    if (value) {
      this.setState({ recaptcha: true })
    }
  }

  render () {
    const { errors, name, contact, loading, sent } = this.state

    if (loading) {
      return (
        <div id="results_page">
          <FilterMainOld search={false} text={'Contact us'} />
          <div class="gc no-gap contact_Spinner">
            <div class="d-2-12 t-3-11 m-1-13 ">
              <svg class="splash-spinner" viewBox="0 0 50 50">
                <circle
                  class="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </div>
          </div>
          <SmallCTA
            sub={'Looking for best of breed software for your business?'}
            button_text={'Check out the app directory'}
            button_link={'/directory/apps/accounting?n=Accounting'}
          />
        </div>
      )
    }
    if (sent) {
      return (
        <div id="results_page">
          <Helmet>
            <title>Contact us - The Right Tool</title>
            <meta
              name="description"
              content="Get in touch with the team at The Right Tool here."
            />
          </Helmet>
          <FilterMainOld search={false} text={'Contact us'} />
          <div class="opacityfade contact_Spinner gc no-gap center_all content">
            <div class="d-4-10 t-3-11 m-2-12 ">
              <p class="main_p">Your message has been sent.</p>
            </div>
          </div>
          <SmallCTA
            sub={'Looking for best of breed software for your business?'}
            button_text={'Check out the app directory'}
            button_link={'/directory/apps/accounting?n=Accounting'}
          />
        </div>
      )
    }

    return (
      <div id="results_page">
        <FilterMainOld search={false} text={'Contact us'} />
        <div class="gc no-gap center_all content">
          <div class="d-4-10 t-3-11 m-2-12 ">
            <p class="main_p">Drop us a line.</p>
            <form onSubmit={this.handleSubmit} class="relative form_container">
              <label class="subtitle" for="name">
                Full Name{' '}
              </label>
              <div class="input-containers">
                <input
                  onChange={this.handleFormChange}
                  id="name"
                  name="name"
                  class={errors.name ? 'errorinputfield' : ''}
                  value={name}
                  required
                ></input>
                <div class={errors.name ? 'error-input' : 'none'}>
                  <span>{errors.name}</span>
                </div>
              </div>

              <label class="subtitle" for="contact">
                Contact Number
              </label>
              <div class="input-containers">
                <input
                  onChange={this.handleFormChange}
                  id="contact"
                  name="contact"
                  class={errors.contact ? 'errorinputfield' : ''}
                  value={contact}
                ></input>
                <div class={errors.contact ? 'error-input' : 'none'}>
                  <span>{errors.contact}</span>
                </div>
              </div>

              <label class="subtitle" for="email">
                Email
              </label>
              <div class="input-containers">
                <input
                  type="email"
                  class="email"
                  placeholder="Email Address"
                  onChange={this.handleFormChange}
                  value={this.email}
                  name="email"
                  id="email"
                  required
                />
                <div class={errors.email ? 'error-input' : 'none'}>
                  <span>{errors.email}</span>
                </div>
              </div>

              <label class="subtitle" for="message">
                Message
              </label>
              <div class="input-containers">
                <textarea
                  id="message"
                  name="message"
                  value={this.message}
                  onChange={this.handleFormChange}
                  required
                />
                <div class={errors.message ? 'error-input' : 'none'}>
                  <span>{errors.message}</span>
                </div>
              </div>
              <div class="input-containers">
                <ReCAPTCHA
                  sitekey="6Lebb0MaAAAAAClMRoyZowsTBi6yc291nfE7jVU2"
                  onChange={(value) => this.onChange(value)}
                />
              </div>
              <div class="input-containers">
                <div class="pointer">
                  <button type="submit">
                    {' '}
                    <Button text={'Submit'} color="blue" type="submit" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <SmallCTA
          sub={'Looking for best of breed software for your business?'}
          button_text={'Check out the app directory'}
          button_link={'/directory/apps/accounting?n=Accounting'}
        />
      </div>
    )
  }
}

export default contact
