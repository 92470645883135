import React, { Component } from "react";
import "./directory.scss";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "aria-components";

class Directory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: "",
      menuActive: false,
      sendlink: false,
      redirectlink: "",
    };
  }

  sent = () => {
    if (this.state.sendlink && this.state.sent) {
      this.setState({ sendlink: false, sent: false });
    }
  };
  componentDidUpdate() {
    this.sent();
  }

  fetchCategories = async () => {
    try {
      const response = await this.props.query();
      const data = await response.data.json();

      this.setState({ data: data }, () => {
        const controller = document.getElementById(`${this.props.id}_button`);

        const target = document.getElementById(`${this.props.id}_ul`);

        const listbox = new Listbox({
          controller,
          target,
          onInit: () => {},
          onStateChange: (e) => {
            if (!e.expanded) {
              e.activeDescendant.click();
            }
          },
          onDestroy: () => {},
        });

      });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    if (this.props.display) {
      this.fetchCategories();
    }
    this.setState({ selected: this.props.button_text });

  }

  ToggleMenu = (e) => {
    this.setState((prevState) => ({
      menuActive: !prevState.menuActive,
    }));
  };

  SetTitle = (category, url, redirectlink) => {
    this.props.parentCallback(category, url);
    this.setState((prevState) => ({
      menuActive: !prevState.menuActive,
      selected: category,
    }));
    this.setState({ redirectlink: redirectlink }, () => {
      this.setState({ sendlink: true, sent: true });
    });
  };

  render() {
    return (
      <div class="table_center">
        <div class={`drop-down`}>
          <span class="drop-down__button">
            {" "}
            <button id={`${this.props.id}_button`} class="controller_list">
              {unescape(this.state.selected)}{" "}
            </button>
            <FontAwesomeIcon
              icon={!this.state.menuActive ? faChevronDown : faChevronDown}
            />
          </span>

          <div class="drop-down__menu-box">
            <ul
              id={`${this.props.id}_ul`}
              class="drop-down__menu controller_UL"
            >
              <li data-name="profile" class="drop-down__item" hidden>
                {this.props.button_text}
              </li>
              {this.state.data.map((category, i) => {
                return (
                  <li
                    onClick={() => this.props.SetTitle(category.name)}
                    data-name="profile"
                    class="drop-down__item"
                    key={`${category.name}_${i}`}
                  >
                    {unescape(category.name)}
                  </li>
                );
              })}
            </ul>

          </div>
        </div>
      </div>
    );
  }
}

export default Directory;
