import React, { useEffect, useState } from 'react'
import NewListing from '../components/newlisting/newlisting'
import Featuredindustry from '../components/FeaturedIndustry/featuredindustry'
import Toggle from '../components/toggle/newtoggle'
import {
  categoryAPI,
  getIndustry,
  getLikes,
  getSubcategory,
} from '../util/service'
import SmallCTA from '../components/small_cta/small_cta'
import Loading from '../components/loading/loading'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import Cookie from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'

const token = Cookie.get('AUTH_TOKEN') ? Cookie.get('AUTH_TOKEN') : null

const Tools = (props) => {
  const [category, setCategory] = useState(props.match.params.category)
  const [LoadingServicesAndSubCategories, setLoadingServicesAndSubCategories] =
    useState(true)
  const [LoadingLikes, setLoadingLikes] = useState(true)
  const [LoadingIndustry, setIndustryLoading] = useState(false)
  const [activeApps, setActiveApps] = useState(true)
  const [industry, setIndustry] = useState(
    props.match.params.industry ? props.match.params.industry : null,
  )
  const [subcategories, setSubcategories] = useState([])
  const [industryData, setIndustryData] = useState([])
  const [showFeatured, setShowFeatured] = useState(false)
  const [services, setServices] = useState([])
  const [likes, setLikes] = useState([])
  const [WishlistAdded, setWishlistAdded] = useState(false)
  const [LikedApps, setLikedApps] = useState([false])

  function fadeIn (el, time) {
    el.style.opacity = 0

    var last = +new Date()
    var tick = function () {
      el.style.opacity = +el.style.opacity + (new Date() - last) / time
      last = +new Date()

      if (+el.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) ||
        setTimeout(tick, 16)
      }
    }

    tick()
  }

  const fadeInDivs = () => {
    let animateboxes = document.getElementsByClassName('animate')
    for (let i = 0; i < animateboxes.length; i++) {
      setTimeout(function () {
        fadeIn(animateboxes[i], 500)
      }, 200 * i)
    }
  }

  const fetchData = async () => {
    try {
      const categorydata = await categoryAPI({
        name: category,
        limit: '3',
        industry: industry,
      })
      const returnJson = await categorydata.data.json()
      setSubcategories(returnJson.subcategories)
      setServices(returnJson.services ? returnJson.services : [])
      setLoadingServicesAndSubCategories(false)
    } catch (error) {
      console.error(error.message)
    }
  }

  const fetchLikes = async () => {
    try {
      const getlikes = await getLikes()
      const likes = await getlikes.data.json()
      setLikes({ likes })
      setLoadingLikes(false)
    } catch (error) {
      console.error(error.message)
    }
  }

  const fetchSubcategory = async (subcategory, i) => {
    try {
      const getMoreListings = await getSubcategory({
        name: subcategory.toLowerCase(),
        industry: industry,
      })
      const data = await getMoreListings.data.json()
      const newArray = [...subcategories]
      for (let j = 3; j < data.apps.length; j++) {
        const appToAdd = data.apps[j]
        newArray[i].apps.push(appToAdd)
      }
      updateAndRender(newArray)
    } catch (error) {
      console.error(error.message)
    }
  }

  const updateAndRender = (newData) => {
    setSubcategories(newData)
    fadeInDivs()
  }

  const fetchIndustry = async () => {
    try {
      const categorydata = await getIndustry({
        name: industry.toLowerCase(),
      })
      const returnJson = await categorydata.data.json()
      setIndustryData(returnJson)
      if (
        returnJson.featured_apps.length > 1 &&
        returnJson.featured_services.length > 0
      ) {
        setShowFeatured(true)
      } else {
        setShowFeatured(false)
      }
      setIndustryLoading(false)
    } catch (error) {
      console.error(error.message)
    }
  }

  const addFunction = (i) => {
    fetch(process.env.REACT_APP_API_URL + `/likes/apps/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        listing: i.url,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let array = {
          listing: {
            url: data.listing,
          },
          url: data.url,
        }
        setWishlistAdded(true)
        setLikedApps(LikedApps.concat(array))
      })
      .catch((error) => {
        console.error(this.state.error)
      })
  }

  const removeFunction = (i) => {
    let urldelete = i.url
    for (let i = 0; i < LikedApps.length; i++) {
      if (LikedApps[i].listing.url === urldelete) {
        let url = LikedApps[i].url
        var array = [...LikedApps]

        array.splice(i, 1)
        setLikedApps(array)
        fetch(
          process.env.REACT_APP_API_URL + `/likes/apps/${url
            .split('/')
            .slice(-2)
            .join('/')}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${token}`,
            },
          },
        )
          .then((data) => {
            setWishlistAdded(true)
          })
          .catch((error) => {
            console.error(this.state.error)
          })
      }
    }
  }

  const change = (arg) => {
    setActiveApps(arg)
  }

  const isitliked = (data) => {
    return likes.likes.some((item) => item.listing.url === data.url)
  }

  useEffect(() => {
    setLoadingServicesAndSubCategories(true)

    setCategory(props.match.params.category)
    if (token) setLoadingLikes(true)
    fetchLikes()
    if (props.match.params.industry) {
      setIndustryLoading(true)
      setIndustry(props.match.params.industry)
    }
  }, [props.location])

  useEffect(() => {
    fetchData()
    fetchIndustry()
  }, [industry, category])

  const ServicesPreview = (props) => {
    if (services.length < props.end) return null
    return (
      <>
        <div class="d-2-12 t-2-12 m-2-12 ">
          <div class="split_between">
            <span class="cloudexperts_preview">Cloud Experts</span>
          </div>
        </div>
        <div class="d-2-12 t-2-12 m-1-13 gc2 profile">
          {services.map((app, index) => {
            //Clean this up belo
            if (index >= props.end || index < props.start) {
              return null
            }

            let likeUrl
            if (token) {
              if (isitliked(app)) {
                for (let i = 0; i < likes.likes.length; i++) {
                  if (likes.likes[i].listing.url === app.url) {
                    likeUrl = likes.likes[i].url
                  }
                }
              }
            }

            return (
              <NewListing
                wishlist={token ? isitliked(app) : false}
                likeURL={likeUrl}
                data={app}
                link={`/profile/cloud-expert`}
                blue={true}
              />
            )
          })}
        </div>
      </>
    )
  }

  const Apps = () => {
    return (
      <>
        <div id="maincontentskip" class={showFeatured ? 'opacityfade' : 'hide'}>
          <div class={'gc  no-gap center_all listing_container'}>
            {showFeatured && <Featuredindustry data={industryData} />}
          </div>
        </div>
        <div class={showFeatured ? ' background_supergrey' : ''}>
          <div class={'gc  no-gap center_all listing_container'}>
            {subcategories.map((item, i) => {
              if (item.apps.length < 1) return null

              return (
                <React.Fragment key={`${i}${item.name}`}>
                  {i === 3 && <ServicesPreview start={0} end={3} />}
                  {i === 6 && <ServicesPreview start={3} end={6} />}
                  {i === 9 && <ServicesPreview start={6} end={9} />}
                  <div class="d-2-12 t-2-12 m-2-12 hr_parent">
                    {' '}
                    <hr />
                  </div>

                  <div class="d-2-12 t-2-12 m-2-12 ">
                    <div class="split_between">
                      <h2>{item.name}</h2>
                    </div>
                  </div>
                  <div key={item.name} class="d-2-12 t-2-12 m-1-13 gc2 profile">
                    {item.apps.map((app, x) => {
                      //Clean this up below
                      let likeUrl
                      if (token) {
                        if (isitliked(app)) {
                          for (let i = 0; i < likes.likes.length; i++) {
                            if (likes.likes[i].listing.url === app.url) {
                              likeUrl = likes.likes[i].url
                            }
                          }
                        }
                      }

                      return (
                        <React.Fragment key={x}>
                          <NewListing
                            wishlist={token && isitliked(app)}
                            likeURL={likeUrl}
                            data={app}
                            className={x > 3 ? 'zeroopacity animate' : ''}
                            link={`/profile/app`}
                          />
                        </React.Fragment>
                      )
                    })}
                  </div>
                  <div class="d-2-12 t-2-12 m-2-12 ">
                    <div class="split_between">
                      <h2></h2>

                      <span
                        class={`${
                          item.apps.length === item.total_app_count
                            ? 'hide'
                            : 'blue pointer strong_span'
                        }`}
                        onClick={() => fetchSubcategory(item.name, i)}
                      >
                        {item.total_app_count > 3 && (
                          <>
                            Show {item.total_app_count - 3} More{' '}
                            <FontAwesomeIcon icon={faLongArrowAltRight} />{' '}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          <SmallCTA
            sub={'Not sure where to start? Try the Free Tool Finder'}
            button_text={'Try the free tool finder'}
            button_link={'/free-tool-finder'}
          />
        </div>
      </>
    )
  }

  const Services = () => {
    return (
      <div id="maincontentskip" class="opacityfade">
        <div class={'gc  no-gap center_all listing_container'}>
          <div class="d-2-12 t-2-12 m-2-12 text_center">
            <h2>{services.length > 0 ? 'Cloud Experts' : 'Empty'}</h2>
          </div>
          <div class="d-2-12 t-2-12 m-1-13 gc2 profile">
            {services.map((service, i) => {
              if (services.length < 1) return <p>No apps cuz</p>
              return (
                <React.Fragment key={i}>
                  <NewListing
                    addFunction={
                      WishlistAdded
                        ? removeFunction.bind(this)
                        : addFunction.bind(this)
                    }
                    wishlist={WishlistAdded}
                    data={service}
                    link={`/profile/cloud-expert`}
                  />
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <SmallCTA
          sub={'Not sure where to start? Try the Free Tool Finder'}
          button_text={'Try the free tool finder'}
          button_link={'/free-tool-finder'}
        />
      </div>
    )
  }

  const loading = [
    LoadingIndustry,
    LoadingServicesAndSubCategories,
    LoadingLikes,
  ]

  if (loading.every((x) => !x)) {
    return (
      <>
        <Helmet>
          <title>
            {' '}
            Find the best {industry ? industry : ''} apps & cloud-experts for
            your business - The Right Tool
          </title>
          <meta
            property="og:image"
            content={
              industry
                ? `https://therighttool.co.nz/TheRightTool_Meta_${industry}.png`
                : 'https://therighttool.co.nz/TheRightTool.jpg'
            }
          />
          <meta
            name="description"
            content={`Real reviews for ${
              industry ? industry : ''
            }} apps to help you find the tools to help you manage your ${
              industry ? industry : ''
            } business. Aotearoa’s leading ${
              industry ? industry : ''
            } app directory. Quickly find the best ${
              industry ? industry : ''
            } apps.`}
          />
        </Helmet>
        {<Toggle activeApps={activeApps} change={change} />}
        {!activeApps ? <Services /> : <Apps />}
      </>
    )
  } else {
    return (
      <>
        {<Toggle activeApps={activeApps} change={change} />}

        <Loading />
      </>
    )
  }
}

export default Tools
