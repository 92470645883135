import React from 'react';
// import logo from './logo.svg';

import Main from './main';
import './assets/css/grid.scss';
import './App.scss';
function App() {
  return (
      <Main/>
  );
}

export default App;
