import React, { Component } from 'react'
import './service_cta.scss'
import Button from '../button/button'
import Listing from '../listing/listing'

class ServiceCta extends Component {
  constructor (props) {
    super(props)
    this.state = {
      featured: [],
      loading: false,
    }
  }

  componentDidMount () {
    this.fetchIndustriesListings()
  }

  fetchIndustriesListings = (e) => {
    fetch(process.env.REACT_APP_API_URL + `/services/?random=4`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          featured: data,
          loading: true,
        })
      })
      .catch((error) => {
        this.setState({ error: error })
        console.log(this.state.error)
      })
  }

  render () {
    if (this.state.loading) {
      const main_text = this.props.text
      const sub = this.props.sub
      const button_text = this.props.button_text
      const button_link = this.props.button_link
      const { featured } = this.state

      return (
        <div id="service_cta" class={`background_blue center_all content`}>
          <div class="gc no-gap">
            <div class="d-4-10 t-3-11 m-2-12 text_center">
              <span class="white">Featured Cloud Experts</span>
              <h2 class="white">{main_text}</h2>
              <p class="white main_p">{sub}</p>
            </div>
          </div>
          <div class="gc no-gap center_all servicecta_container">
            <div class="d-2-12 t-3-11 m-1-13 gc profile">
              <Listing
                data={featured[0]}
                link={`/profile/cloud-expert/${featured[0].url
                  .split('/')
                  .slice(-2)
                  .join('/')}`}
              />
              <Listing
                data={featured[1]}
                link={`/profile/cloud-expert/${featured[1].url
                  .split('/')
                  .slice(-2)
                  .join('/')}`}
              />
            </div>
            <div class="d-2-12 t-3-11 m-1-13 gc profile">
              <br></br> <br></br>
            </div>
            <div class="d-2-12 t-3-11 m-1-13 gc profile">
              <Listing
                data={featured[2]}
                link={`/profile/cloud-expert/${featured[2].url
                  .split('/')
                  .slice(-2)
                  .join('/')}`}
              />
              <Listing
                data={featured[3]}
                link={`/profile/cloud-expert/${featured[3].url
                  .split('/')
                  .slice(-2)
                  .join('/')}`}
              />
            </div>
          </div>
          <div class="gc no-gap">
            <div class="d-4-10 t-3-11 m-1-13 text_center">
              <Button text={button_text} color="white" link={button_link} />
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ServiceCta
